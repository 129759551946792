import { Container, Navbar, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/just_breathe_white.svg";
import { SlUser } from "react-icons/sl";
import { GoChevronDown } from "react-icons/go";
import { RiLogoutCircleLine } from "react-icons/ri";
import WebService from "../../Services/WebService";
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import { updateProfileImage } from "../../Redux/actions";
import { useEffect, useState } from "react";
import notificationIcon from "../../assets/images/Notification1.svg";

const Header = () => {
  const profileImage = useSelector((state: any) => state.profile.profileImage ?? "");
  const dispatch = useDispatch();
  const [image, setImage] = useState("")
  const meCall = () => {
    return WebService.getAPI({
      action: `user/me`,
      body: null,
      isShowError: true,
    })
      .then((res: any) => {
        dispatch(updateProfileImage(res.result.userProfileImage));
        localStorage.setItem("profileImage", res.result.userProfileImage);
        setImage(res.result.userProfileImage)
      })
      .catch((error) => {
        return error;
      });
  };

  useEffect(() => {
    meCall();
  }, [image])
  return (
    <>
      <header className="site-header">
        <Container fluid className="header-nav">
          <Navbar expand="lg">
            <Navbar.Brand className="me-5">
              <img src={Logo} width={235} height={47} alt="Just Breathe" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <div className="d-flex align-items-center ms-auto gap-3">
              <img
                src={notificationIcon}
                alt=""
              />
              <Dropdown className="profile-dd">
                <Dropdown.Toggle>
                  <div className="d-flex gap-2">
                    <div>
                      <img
                        src={image}
                        width={35}
                        height={35}
                        className=" rounded-circle object-fit-cover"
                        alt=""
                        onError={(error: any) => {
                          meCall()
                        }}
                      />
                    </div>
                    <div>
                      <p className="font-14 mb-0 text-dark font-medium">
                        {localStorage.getItem("admin_name")}
                      </p>
                      <p className="font-12 mb-0 text-secondary">
                        {localStorage.getItem("admin_email")}
                      </p>
                    </div>
                  </div>
                  <GoChevronDown size={16} className="icon" />
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  <Link to="/Profile" className=" dropdown-item">
                    <SlUser size={14} className="me-2" /> My Profile
                  </Link>
                  <Link
                    to="/login"
                    className=" dropdown-item"
                    onClick={() => {
                      WebService.logout()
                    }}
                  >

                    <RiLogoutCircleLine size={14} className="me-2" /> Logout
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Navbar>
        </Container>
      </header>
    </>
  );
};
export default Header;
