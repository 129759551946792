import { Outlet } from "react-router-dom";
import Header from "./Header/Header";
import VerticalMenu from "./LeftMenu/VerticalMenu";
import { useState } from "react";

const Main = () => {
  const[visible, setVisible] = useState<boolean>(true)
  return (
    <>
      <Header />
      <div id="main-app" className={visible ? "layout-veritcle-menu" : "layout-veritcle-menu menumize-menu"}>
        {/* <VerticalMenu /> */}
        <VerticalMenu
        visible={visible}
        toggleClass=""
        onToggleHandler={(className:boolean)=>{setVisible(className)}}
        />
        <div className="app-body kpi-dahboard-page">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Main;
