import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WebService from "../../../Services/WebService";

interface PropData {
  customClass?: any;
}

const TableLoader = (props: PropData) => {
  let params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (params.token) {
      localStorage.setItem("user_access_token", params.token);
      meCall();
    }
  }, []);
  const meCall = () => {
    return WebService.getAPI({
      action: `me`,
      body: null,
      isShowError: true,
    })
      .then((res: any) => {
        localStorage.setItem("user_name", res.first_name + " " + res.last_name);
        localStorage.setItem("user_email", res.email);
        localStorage.setItem("user_id", res.id);
        localStorage.setItem("user_type", res.role.role);
         navigate("/dashboard");
      })
      .catch((error) => {
        WebService.logout()
        return error;
      });
  };

  return (
    <>
      <div
       
        className="mb-0 table-wrap position-relative w-100"
      >
        <div className={ props.customClass
            ? props.customClass +" component-loader":"component-loader"}>
          <div className="loader"></div>
        </div>
      </div>
    </>
  );
};

export default TableLoader;
