import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactECharts from "echarts-for-react";

const FullScreenChart = (props: any) => {    
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.src ? "Image" : "Live Chart"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: props.isChartView ? "500px" : "800px" }}>
                <div>
                    {
                        props.isChartView ?
                            <ReactECharts
                                option={props.outdoorOptionGraphData}
                                style={{ height: "450px" }}
                            />
                            :
                            <img
                                src={props.src}
                                style={{ height: "750px" }}
                            />
                    }
                </div>
            </Modal.Body>
            {/* <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer> */}
        </Modal>
    );
}


export default FullScreenChart;