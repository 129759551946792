import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { PiSealWarning } from "react-icons/pi";
import WebService from "../../../Services/WebService";
interface PropData {
  show: boolean;
  action: string;
  onDelete: any;
  deleteMsg: any;
  btn: any
  deleteMethod?: any;

}

const DeleteModal = (props: PropData) => {
  const [showDelete, setShowDelete] = useState(false);
  useEffect(() => {
    setShowDelete(props.show);
  });
  const handleDelete = () => {
    WebService.addLoader("save11")
    if (props.deleteMethod === "PUT") {
      WebService.putAPI({
        action: props.action,
        body: null,
      }).then((res: any) => {
        toast(res.message ?? "Deleted successfully!", {
          position: "top-right",
          type: "success",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        WebService.removeLoader("save11")
        setShowDelete(false);
        props.onDelete(true);
      });
    } else {
      WebService.deleteAPI({
        action: props.action,
        body: null,
      }).then((res: any) => {
        toast(res.message ?? "Deleted successfully!", {
          position: "top-right",
          type: "success",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        WebService.removeLoader("save11")
        setShowDelete(false);
        props.onDelete(true);
      });
    }

  };
  const handleCloseDelete = () => {
    setShowDelete(false);
    props.onDelete(false);
  };
  return showDelete ? (
    <Modal show={showDelete} onHide={handleCloseDelete} centered>
      <Modal.Header
        closeButton
        onClick={() => handleCloseDelete}
        className=" border-0 pb-0"
      ></Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <h2 className="text-danger">
            <PiSealWarning size={70} />
          </h2>
          <h4 className="font-18">
            {props.deleteMsg
              ? props.deleteMsg
              : "Are you sure you want to delete"}

          </h4>
        </div>
      </Modal.Body>
      <Modal.Footer className=" justify-content-center border-0 pt-0 mb-2">
        <button className="btn btn-danger w-25 " onClick={handleCloseDelete} >
          {props.btn === "YES/NO" ? "NO" : "Cancel"}
        </button>
        <Button className="btn-brand-1 w-25" onClick={handleDelete} id="save11">
          {props.btn === "YES/NO" ? "Yes" : "Delete"}
        </Button>
      </Modal.Footer>
    </Modal>
  ) : (
    ""
  );
};

export default DeleteModal;
