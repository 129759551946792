import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import GaugeChart from "react-gauge-chart";
import WebService from "../../Services/WebService";
import ReactECharts from "echarts-for-react";
import Air from "../../assets/images/icons/air.svg";
import Lungs from "../../assets/images/icons/lungs.svg";
import Nitrogen from "../../assets/images/icons/nitrogen.svg";
import Ammonia from "../../assets/images/icons/ammonia.svg";
import Carbon from "../../assets/images/icons/carbon.svg";
import Temprature from "../../assets/images/icons/temprature.svg";
import Footprint from "../../assets/images/icons/footprint.svg";
import Ozone from "../../assets/images/icons/ozone.svg";
import Molecule from "../../assets/images/icons/molecule.svg";
import Humidity from "../../assets/images/icons/humidity.svg";
import Fridgesimg from "../../assets/images/fridgesimg.png";
import Analytics from "../../assets/images/analytics.svg";
import LowR from "../../assets/images/lowR.svg";
import LowG from "../../assets/images/lowG.svg";
import OzoneLayero3 from "../../assets/images/ozone-layer-o3.svg";
import Sun from "../../assets/images/sun.svg";
import { HiMiniViewfinderCircle } from "react-icons/hi2";
import { toast } from "react-toastify";
import RecordSpan from "./RecordSpan";
import { useLocation, useParams } from "react-router-dom";
import HelperService from "../../Services/HelperService";
import axios from "axios";
import moment from "moment";
import PageTitle from "../PageTitle";
import { useTranslation } from "react-i18next";
import liveImage from "../../assets/images/liveimage.svg";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import FullScreenChart from "../FullScreenChart";
import { GrRefresh } from "react-icons/gr";
import { FaRedoAlt, FaSyncAlt } from "react-icons/fa";

export interface WeatherData {
  temperature: string;
  city: string;
  weatherDiscription?: string;
  icon?: string;
}

const CommandCentreNewFlow = (props: any) => {
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation();
  const { register, formState: { errors }, setValue, handleSubmit, watch, reset, clearErrors } = useForm();
  const [webscocketIndoorData, setWebSocketIndoorData] = useState<any>(null);
  const [webSocketOutdoorData, setWebsocketOutdoorData] = useState<any>(null);
  const [companyData, setCompanyData] = useState<any>(null);
  const [indoorData, setIndoorData] = useState<any>(null);
  const [outdoorData, setOutdoorData] = useState<any>(null);
  const [stationData, setStationData] = useState<any>(null);
  const [machineData, setMachineData] = useState<any>(null);
  const [airScopeData, setAirscopeData] = useState<any>(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [isChartView, setIsChartView] = useState(true);
  const state = useLocation();
  const navigate = useNavigate();
  const watchVariable = watch();
  let params = useParams();
  let machineId = params?.machineId;
  let companyId = params?.companyId;
  let outdoorSensorId = params?.outdoorSensorId;
  const [stationParameterRangeData, setStationParameterRangeData] = useState<any>([]);

  const [outdoorOptionGraphData, SetOutdoorOptionsGraphData] = useState<any>(
    {}
  );
  const [weatherData, setWeatherData] = useState<WeatherData | null>(() => {
    const storedData = localStorage.getItem("weatherAPIData");
    return storedData ? JSON.parse(storedData) : null;
  });

  const indoorLabels = [
    "pm1", "pm2_5", "pm10", "temperature1", "nh3", "o2",
    "humidity", "tvoc", "decibel", "co2",
    "o3", "co", "no2", "luxlevel"
  ];

  const formatLabel = (label: any) => {
    switch (label) {
      case "pm2_5":
        return "PM2.5";
      case "pm10":
        return "PM10";
      case "nh3":
        return "NH3";
      case "o2":
        return "O2";
      case "humidity":
        return "Humidity";
      case "tvoc":
        return "TVOC";
      case "decibel":
        return "Decibel";
      case "co2":
        return "CO2";
      case "o3":
        return "O3";
      case "co":
        return "CO";
      case "no2":
        return "NO2";
      case "luxlevel":
        return "Lux Level";
      default:
        return label;
    }
  };


  const outdoorLabels = [
    "pm1", "pm2_5", "pm10", "temperature1", "nh3", "o2",
    "rh", "tvoc", "decibel", "co2",
    "o3", "co", "no2", "luxlevel"
  ];


  const formatOutdoorLabel = (label: any) => {
    switch (label) {
      case "pm2_5":
        return "PM2.5";
      case "pm10":
        return "PM10";
      case "nh3":
        return "NH3";
      case "o2":
        return "O2";
      case "rh":
        return "Relative Humidity";
      case "tvoc":
        return "TVOC";
      case "decibel":
        return "Decibel";
      case "co2":
        return "CO2";
      case "o3":
        return "O3";
      case "co":
        return "CO";
      case "no2":
        return "NO2";
      case "luxlevel":
        return "Lux Level";
      default:
        return label;
    }
  };

  const [indoorIndex, setIndoorIndex] = useState(0);
  const [outdoorIndex, setOutdoorIndex] = useState(0);

  const [gaugeChartIndoorData, setGaugeChartIndoorData] = useState<any>([]);
  const [gaugeChartOutdoorData, setGaugeChartOutdoorData] = useState<any>([]);

  useEffect(() => {
    if (webscocketIndoorData) {
      setGaugeChartIndoorData(indoorLabels.map((label) => ({
        label,
        value: webscocketIndoorData[label] || 0
      })));
    }
    if (webSocketOutdoorData) {
      setGaugeChartOutdoorData(outdoorLabels.map((label) => ({
        label,
        value: webSocketOutdoorData[label] || 0
      })));
    }
  }, [webscocketIndoorData, webSocketOutdoorData]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndoorIndex((prev) => (prev + 1) % indoorLabels.length);
      setOutdoorIndex((prev) => (prev + 1) % outdoorLabels.length);
    }, 60000);

    return () => clearInterval(interval);
  }, []);


  const BASE_URL = "https://api.openweathermap.org/";
  const lat = "18.55685784899872";
  const lon = "73.76938563104547";
  const apiKey = "74fc72f9df6e931a1cacc472c29b37b3";

  useEffect(() => {
    getIndoorData(machineId ?? "");
    getWebsocketView(machineId ?? "", companyId ?? "");
    // getWeatherData();
    getStationDetails();
    // getRecentDataForMachine();
    // getRecentDataForAirscope();
  }, []);

  useEffect(() => {
    let role = localStorage.getItem("role");
    if (role === "MANAGER" || role === "CLIENT") {
      navigate("/access-denied");
    }
  });



  const getWeatherData = (lat: any, long: any) => {
    const lastFetchDate = localStorage.getItem("weatherDataCollectedDate");
    const currentDate = new Date().toISOString().split("T")[0];
    // if (!lastFetchDate || lastFetchDate !== currentDate) {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    return axios
      .get(
        `${BASE_URL}data/2.5/weather?lat=${lat}&lon=${long}&appid=${apiKey}`,
        {
          headers: headers,
        }
      )
      .then((response: any) => {
        let data: WeatherData = {
          temperature: (response?.data?.main?.temp - 273.15).toFixed(2),
          city: response?.data?.name,
          weatherDiscription: response?.data?.weather[0]?.description,
          icon: HelperService.getIconForWeather(
            response?.data?.weather[0]?.icon
          ),
        };
        localStorage.setItem("weatherDataCollectedDate", currentDate);
        localStorage.setItem("weatherAPIData", JSON.stringify(data));
        setWeatherData(data);
      })
      .catch((error: any) => { });
    // }
  };


  const getWebsocketView = (machineId: string, companyId: string) => {
    props.socket.onmessage = function (event: any) {
      let data = JSON.parse(event.data);

      let topic = data.topic;
      let content = data.content;
    };

    props.socket.onopen = function () {
      console.log("WebSocket connection opened.");

      // Subscribe to a topic
      props.socket.send(
        JSON.stringify({
          action: "subscribe",
          topic: `Devices/M2C/ST_${machineId}/stationData`,
        })
      );

      // Indoor
      props.socket.send(
        JSON.stringify({
          action: "subscribe",
          topic: `Devices/M2C/ST_${companyId}_${machineId}/indoor`,
        })
      );

      // Outdoor
      if (!watchVariable.outdoorSensorId) {
        props.socket.send(
          JSON.stringify({
            action: "subscribe",
            topic: `Devices/M2C/OD_${outdoorSensorId}/airScopeDataOutdoor`,
          })
        );
      }

      // company data
      props.socket.send(
        JSON.stringify({
          action: "subscribe",
          topic: `Devices/M2C/ST_${companyId}_${machineId}/stationData`,
        })
      );

      // airscope data
      props.socket.send(
        JSON.stringify({
          action: "subscribe",
          topic: `Devices/M2C/ST_${companyId}_${machineId}/airScopeData`,
        })
      );

      props.socket.onmessage = (event: any) => {
        console.log("event on message===>>", event);
        console.log("Received message:", event?.data);

        let data = JSON.parse(event?.data);
        console.log("dataaa", data);

        if (
          data?.topic ===
          `Devices/M2C/ST_${companyId}_${machineId}/airScopeData`
        ) {
          console.log("indoor data", data?.content);

          console.log("indoor111", JSON.parse(data?.content));
          setWebSocketIndoorData(JSON.parse(data?.content));
          console.log("data.set");
        }
        if (
          data.topic ===
          `Devices/M2C/OD_${outdoorSensorId}/airScopeDataOutdoor`
        ) {
          console.log("outdoor data", data?.content);
          setWebsocketOutdoorData(JSON.parse(data?.content));
          // setPM25OutdoorObject(
          //   HelperService.getPM25Object(JSON.parse(data?.content)?.pm2_5)
          // );
        }
        if (
          data.topic === `Devices/M2C/ST_${companyId}_${machineId}/stationData`
        ) {
          console.log("station data", data?.content);
          setMachineData(JSON.parse(data?.content))
          // setWebsocketOutdoorContent(JSON.parse(data?.content));
          // setPM25OutdoorObject(
          //   HelperService.getPM25Object(JSON.parse(data?.content)?.pm2_5)
          // );
        }
        if (
          data.topic === `Devices/M2C/ST_${companyId}_${machineId}/airScopeData`
        ) {
          console.log("airscope data", data?.content);
          setAirscopeData(JSON.parse(data?.content))
        }

        props.socket.onclose = function () {
          console.log("WebSocket connection closed.");
        };
      };
    };
  };


  const closeSocket = () => {
    console.log("close socket");
    props.socket.close();
  };

  const getIndoorData = (stationId: string) => {
    setLoader(true);
    return WebService.getAPI({
      action: `iot/dashboard?stationId=${stationId}`,
      body: null,
    })
      .then((res: any) => {
        setIndoorData(res?.indoor);
        setOutdoorData(res?.outdoor);

        const keys = Object.keys(res?.indoor?.arrAQI7Days || {})
          .map((key) => moment(key).format("MM-DD-YYYY"))
          .sort((a, b) => moment(a, "MM-DD-YYYY").diff(moment(b, "MM-DD-YYYY")));

        const valuesIndoor = Object.values(res?.indoor?.arrAQI7Days || {});
        const valuesOutdoor = Object.values(res?.outdoor?.arrAQI7Days || {});

        SetOutdoorOptionsGraphData({
          grid: { top: 20, right: 30, bottom: 40, left: 50 },
          xAxis: {
            type: "category",
            data: keys,
            axisLabel: {
              rotate: 45,
              formatter: (value: any) => value.split(" ")[0],
              fontSize: 8,
            },
          },
          yAxis: [
            {
              type: "value",
              axisLabel: {
                formatter: "{value}",
                fontSize: 10,
              },
              axisLine: {
                lineStyle: {
                  color: "#5470C6",
                },
              },
            },
            {
              type: "value",
              axisLabel: {
                formatter: "{value}",
              },
              axisLine: {
                lineStyle: {
                  color: "#91CC75",
                },
              },
              position: "right", // Position the second y-axis on the right
              offset: 30, // Offset to avoid overlap with the first y-axis
            },
          ],
          series: [
            {
              name: "Indoor",
              data: valuesIndoor,
              type: "line",
              smooth: true,
              lineStyle: {
                width: 1,
              },
              itemStyle: {
                color: "#32CD32",
              },
              animationDuration: 2000,
              animationEasing: "cubicOut",
              yAxisIndex: 0,
            },
            {
              name: "Ambient",
              data: valuesOutdoor,
              type: "line",
              smooth: true,
              lineStyle: {
                width: 1,
              },
              itemStyle: {
                color: "#FFA500",
              },
              animationDuration: 2000, // Animation duration in milliseconds
              animationEasing: "cubicOut", // Animation easing function
              yAxisIndex: 1, // Link to the second y-axis
            },
          ],
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(50, 50, 50, 0.7)", // Customize tooltip background
            borderColor: "#333", // Customize tooltip border color
            borderWidth: 1, // Customize tooltip border width
            textStyle: {
              color: "#fff", // Customize tooltip text color
            },
          },
        });
        setLoader(false);
      })
      .catch((error: any) => {
        console.log("error", error);
        toast.error(error?.response?.data?.message);
        setLoader(false);
        return null;
      });
  };

  const getStationDetails = () => {
    setLoader(true);
    WebService.getAPI({
      action: `command-centre/station/detail?station_id=${state?.state?.id}`,
      body: null,
    }).then((res: any) => {
      setLoader(false);
      setStationData(res.result);
      getWeatherData(res.result.latitude, res.result.longitude);
      setValue("outdoorSensorId", res.result.outdoorSensorId);
    }).catch((error: any) => {
      setLoader(false);
      return false;
    })
  }

  // const getRecentDataForMachine = () => {
  //   setLoader(true)
  //   WebService.getAPI({
  //     action: `command-centre/station/recentdata?station_id=${state?.state?.id}`,
  //     body: null
  //   }).then((res: any) => {
  //     setLoader(false);
  //     setMachineData(res.result);
  //   }).catch((error: any) => {
  //     setLoader(false);
  //     return false;
  //   })
  // }

  // const getRecentDataForAirscope = () => {
  //   setLoader(true);
  //   WebService.getAPI({
  //     action: `/command-centre/station/recentdata/airscope?station_id=${state?.state?.id}`,
  //     body: null,
  //   }).then((res: any) => {
  //     setLoader(false);
  //     setAirscopeData(res.result);
  //   }).catch((error: any) => {
  //     setLoader(false);
  //     return false;
  //   })
  // }

  const handleRefreshData = () => {
    WebService.getAPI({
      action: `command-centre/station/refresh?station_id=${machineId}`,
      body: null,
    }).then((res: any) => {
    }).catch((error: any) => {
      return false;
    })
  }

  let temperature1 = airScopeData?.temperature1;
  let temperature2 = airScopeData?.temperature2;
  let lowestTemperature = Math.min(temperature1, temperature2);

  let outdoorTemperature1 = webSocketOutdoorData?.temperature1;
  let outdoorTemperature2 = webSocketOutdoorData?.temperature2;
  let outdoorLowestTemperature = Math.min(outdoorTemperature1, outdoorTemperature2);

  const getGaugeChartPercent = (value: any, range: any) => {
    if (value > range?.toRange) {
      return 1;
    }
    return value / 100;
  };

  const getGaugeChartColor = (value: any, range: any) => {
    if (value > range?.toRange) {
      return "red";
    }
    return value > range.fromRange ? "orange" : "green";
  };

  const getParameterRangeData = () => {
    const body = {
      site_id: state.state?.siteId,
    }
    var queryParam = new URLSearchParams(body)
    return WebService.getAPI({
      action: `parameter-range?` + queryParam,
      body: null,
      isShowError: true
    }).then((res: any) => {
      setStationParameterRangeData(res.list)
    }).catch((error: any) => {
      return error;
    })
  }

  return (
    <>

      <Row>
        <Col>
          <div className="justify-content-between align items-center mb-3" style={{ display: "flex" }}>
            <PageTitle Title={t('Station_Details')} />
            <div onClick={handleRefreshData} >
              <FaSyncAlt style={{ height: "30px", width: "30px" }} title="Refresh Page" />
            </div>

          </div>
        </Col>
      </Row>

      <div className="commandCentre-page mb-4">
        <Row className="g-3">
          <Col lg={5} sm={5}>
            <Row className="g-3">
              <Col lg={6} sm={6}>
                <div className="white-card-box">
                  <div className="mb-2"><strong>Indoor Data</strong>
                    &nbsp; <img src={liveImage} width="38" alt="Logo" />
                    &nbsp; <FaRedoAlt style={{ height: "20px", width: "20px" }} title="Restart" /></div>
                  <Row className="g-3">
                    <Col lg={6} sm={6} xs={6}>
                      <RecordSpan
                        webscocketIndoorData={airScopeData?.pm1}
                        indoorLiveData={indoorData?.livePm1}
                        unit="µg/m³"
                        pointer="PM 1"
                        logo={Lungs}
                        fontColor={"#2dc937"}
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <RecordSpan
                        webscocketIndoorData={airScopeData?.pm2_5}
                        indoorLiveData={indoorData?.livePm25}
                        unit="μg/m³"
                        pointer="PM 2.5"
                        logo={Lungs}
                        fontColor={"#2dc937"}
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <RecordSpan
                        webscocketIndoorData={airScopeData?.pm10}
                        indoorLiveData={indoorData?.livePm10}
                        unit="μg/m³"
                        pointer="PM 10"
                        logo={Air}
                        fontColor={"#99c140"}
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <RecordSpan
                        webscocketIndoorData={airScopeData?.tvoc}
                        indoorLiveData={indoorData?.liveTvoc}
                        unit="µg/m³"
                        pointer="TVOC"
                        logo={Molecule}
                        fontColor={"#cc3232"}
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <RecordSpan
                        webscocketIndoorData={airScopeData?.co2}
                        indoorLiveData={indoorData?.liveSo2}
                        unit="ppm"
                        pointer="CO2"
                        logo={Footprint}
                        fontColor={"#cc3232"}
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <RecordSpan
                        webscocketIndoorData={lowestTemperature}
                        indoorLiveData={indoorData?.liveTemperature}
                        unit="C"
                        pointer="Temp"
                        logo={Temprature}
                        fontColor={"#2dc937"}
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <RecordSpan
                        webscocketIndoorData={airScopeData?.humidity}
                        indoorLiveData={indoorData?.liveRh}
                        unit="%"
                        pointer="RH"
                        logo={Humidity}
                        fontColor={"#db7b2b"}
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <RecordSpan
                        webscocketIndoorData={airScopeData?.o2}
                        indoorLiveData={indoorData?.liveCo}
                        unit="ppm"
                        pointer="O2"
                        logo={Footprint}
                        fontColor={"#99c140"}
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <RecordSpan
                        webscocketIndoorData={airScopeData?.o3}
                        indoorLiveData={indoorData?.liveO3}
                        unit="µg/m³"
                        pointer="O3"
                        logo={Ozone}
                        fontColor={"#99c140"}
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <RecordSpan
                        webscocketIndoorData={airScopeData?.co}
                        indoorLiveData={indoorData?.liveCo}
                        unit="µg/m³"
                        pointer="CO"
                        logo={Carbon}
                        fontColor={"#e7b416"}
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <RecordSpan
                        webscocketIndoorData={airScopeData?.no2}
                        indoorLiveData={indoorData?.liveSo2}
                        unit="μg/m³"
                        pointer="NO2"
                        logo={Nitrogen}
                        fontColor={"#e7b416"}
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <RecordSpan
                        webscocketIndoorData={airScopeData?.nh3}
                        indoorLiveData={indoorData?.liveNh3}
                        unit="μg/m³"
                        pointer="NH3"
                        logo={Ammonia}
                        fontColor={"#db7b2b"}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6} sm={6}>
                <div className="white-card-box">
                  <div className="mb-2"><strong>Ambient Data</strong>
                    &nbsp; <img src={liveImage} width="38" alt="Logo" />
                    &nbsp;
                    <FaRedoAlt style={{ height: "20px", width: "20px" }} title="Restart" /></div>
                  <Row className="g-3">
                    <Col lg={6} sm={6} xs={6}>
                      <RecordSpan
                        webscocketIndoorData={webSocketOutdoorData?.pm1}
                        indoorLiveData={outdoorData?.livePm1}
                        unit="µg/m³"
                        pointer="PM 1"
                        logo={Lungs}
                        fontColor={"#2dc937"}
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <RecordSpan
                        webscocketIndoorData={webSocketOutdoorData?.pm2_5}
                        indoorLiveData={outdoorData?.livePm25}
                        unit="μg/m³"
                        pointer="PM 2.5"
                        logo={Lungs}
                        fontColor={"#2dc937"}
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <RecordSpan
                        webscocketIndoorData={webSocketOutdoorData?.pm10}
                        indoorLiveData={outdoorData?.livePm10}
                        unit="μg/m³"
                        pointer="PM 10"
                        logo={Air}
                        fontColor={"#99c140"}
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <RecordSpan
                        webscocketIndoorData={webSocketOutdoorData?.tvoc}
                        indoorLiveData={outdoorData?.liveTvoc}
                        unit="µg/m³"
                        pointer="TVOC"
                        logo={Molecule}
                        fontColor={"#cc3232"}
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <RecordSpan
                        webscocketIndoorData={webSocketOutdoorData?.co2}
                        indoorLiveData={outdoorData?.liveSo2}
                        unit="ppm"
                        pointer="CO2"
                        logo={Footprint}
                        fontColor={"#cc3232"}
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <RecordSpan
                        webscocketIndoorData={outdoorLowestTemperature}
                        indoorLiveData={outdoorData?.liveTemperature}
                        unit="C"
                        pointer="Temp"
                        logo={Temprature}
                        fontColor={"#2dc937"}
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <RecordSpan
                        webscocketIndoorData={webSocketOutdoorData?.rh}
                        indoorLiveData={outdoorData?.liveRh}
                        unit="%"
                        pointer="RH"
                        logo={Humidity}
                        fontColor={"#db7b2b"}
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <RecordSpan
                        webscocketIndoorData={webSocketOutdoorData?.o2}
                        indoorLiveData={outdoorData?.liveCo}
                        unit="ppm"
                        pointer="O2"
                        logo={Footprint}
                        fontColor={"#99c140"}
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <RecordSpan
                        webscocketIndoorData={webSocketOutdoorData?.o3}
                        indoorLiveData={outdoorData?.liveO3}
                        unit="µg/m³"
                        pointer="O3"
                        logo={Ozone}
                        fontColor={"#99c140"}
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <RecordSpan
                        webscocketIndoorData={webSocketOutdoorData?.co}
                        indoorLiveData={outdoorData?.liveCo}
                        unit="µg/m³"
                        pointer="CO"
                        logo={Carbon}
                        fontColor={"#e7b416"}
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <RecordSpan
                        webscocketIndoorData={webSocketOutdoorData?.no2}
                        indoorLiveData={outdoorData?.liveNo2}
                        unit="μg/m³"
                        pointer="NO2"
                        logo={Nitrogen}
                        fontColor={"#e7b416"}
                      />
                    </Col>
                    <Col lg={6} sm={6} xs={6}>
                      <RecordSpan
                        webscocketIndoorData={webSocketOutdoorData?.nh3}
                        indoorLiveData={outdoorData?.liveNh3}
                        unit="μg/m³"
                        pointer="NH3"
                        logo={Ammonia}
                        fontColor={"#db7b2b"}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={7} sm={7}>
            <Row className="g-3">
              <Col lg={6} sm={6}>
                <div className="white-card-box py-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h5 className="h5-title">Recent data from Machine <img src={liveImage} width="38" alt="Logo" /></h5>
                    </div>
                    <div className="dates ms-auto">
                      <span>Date & Time :</span> {moment(machineData?.dateTime).format("MM-DD-YYYY HH:mm:ss") || "-"}
                    </div>
                  </div>
                  <div className="brderBtm"></div>
                  <Row className="g-3 mb-2">
                    <Col lg={3} sm={3} xs={6}>
                      <span className="main-titles">Moisture Level</span>
                      <br />
                      <span className="sub-titles">{machineData?.moistureLevel || 0}</span>
                    </Col>
                    <Col lg={3} sm={3} xs={6}>
                      <span className="main-titles">Water Level</span>
                      <br />
                      <span className="sub-titles">{machineData?.waterLevel || 0}</span>
                    </Col>
                    <Col lg={3} sm={3} xs={6}>
                      <span className="main-titles">PM1</span>
                      <br />
                      <span className="sub-titles">{machineData?.pm1 || 0}</span>
                    </Col>
                    <Col lg={3} sm={3} xs={6}>
                      <span className="main-titles">PM2.5</span>
                      <br />
                      <span className="sub-titles">{machineData?.pm2_5 || 0}</span>
                    </Col>
                  </Row>
                  <Row className="g-3 mb-2">
                    <Col lg={3} sm={3} xs={6}>
                      <span className="main-titles">PM10</span>
                      <br />
                      <span className="sub-titles">{machineData?.pm10 || 0}</span>
                    </Col>
                    <Col lg={3} sm={3} xs={6}>
                      <span className="main-titles">Temperature</span>
                      <br />
                      <span className="sub-titles">{machineData?.temperature.toFixed(2) || 0}</span>
                    </Col>
                    <Col lg={3} sm={3} xs={6}>
                      <span className="main-titles">Humidity</span>
                      <br />
                      <span className="sub-titles">{machineData?.humidity.toFixed(2) || 0}</span>
                    </Col>
                    <Col lg={3} sm={3} xs={6}>
                      <span className="main-titles">Lux</span>
                      <br />
                      <span className="sub-titles">{machineData?.lux.toFixed(2) || 0}</span>
                    </Col>
                  </Row>
                  <Row className="g-3">
                    <Col lg={3} sm={3} xs={6}>
                      <span className="main-titles">Decibel</span>
                      <br />
                      <span className="sub-titles">{machineData?.db || 0}</span>
                    </Col>
                    <Col lg={3} sm={3} xs={6}>
                      <span className="main-titles">Battery Level</span>
                      <br />
                      <span className="sub-titles">{machineData?.batteryLevel || 0}</span>
                    </Col>
                    <Col lg={3} sm={3} xs={6}>
                      <span className="main-titles">Current</span>
                      <br />
                      <span className="sub-titles">{machineData?.amp || 0}</span>
                    </Col>
                    <Col lg={3} sm={3} xs={6}>
                      <span className="main-titles">Voltage</span>
                      <br />
                      <span className="sub-titles">{machineData?.volt || 0}</span>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6} sm={6}>
                <div className="white-card-box">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h5 className="h5-title">Recent data from Airscope <img src={liveImage} width="38" alt="Logo" /></h5>
                    </div>
                    <div className="dates ms-auto">
                      <span>Date & Time :</span> {moment(airScopeData?.dateTime).format("MM-DD-YYYY HH:mm:ss") || "-"}
                    </div>
                  </div>
                  <div className="brderBtm"></div>
                  <Row className="g-3 mb-2">
                    <Col>
                      <span className="main-titles">PM1</span>
                      <br />
                      <span className="sub-titles">{airScopeData?.pm1 || 0}</span>
                    </Col>
                    <Col>
                      <span className="main-titles">PM2.5</span>
                      <br />
                      <span className="sub-titles">{airScopeData?.pm25 || airScopeData?.pm2_5 || 0}</span>
                    </Col>
                    <Col>
                      <span className="main-titles">PM10</span>
                      <br />
                      <span className="sub-titles">{airScopeData?.pm10 || 0}</span>
                    </Col>
                    <Col>
                      <span className="main-titles">Temperature 1</span>
                      <br />
                      <span className="sub-titles">{airScopeData?.temperature1 || 0} </span>
                    </Col>
                    <Col>
                      <span className="main-titles">NH3</span>
                      <br />
                      <span className="sub-titles">{airScopeData?.nh3 || 0}</span>
                    </Col>
                  </Row>
                  <Row className="g-3 mb-2">
                    <Col>
                      <span className="main-titles">02</span>
                      <br />
                      <span className="sub-titles">{airScopeData?.o2 || 0}</span>
                    </Col>
                    <Col>
                      <span className="main-titles">Temperature 2</span>
                      <br />
                      <span className="sub-titles">{airScopeData?.temperature2 || 0}</span>
                    </Col>
                    <Col>
                      <span className="main-titles">Humidity</span>
                      <br />
                      <span className="sub-titles">{airScopeData?.humidity || 0}</span>
                    </Col>
                    <Col>
                      <span className="main-titles">TVOC</span>
                      <br />
                      <span className="sub-titles">{airScopeData?.tvoc || 0}</span>
                    </Col>
                    <Col>
                      <span className="main-titles">Decibel</span>
                      <br />
                      <span className="sub-titles">{airScopeData?.decibel || airScopeData?.db || 0}</span>
                    </Col>
                  </Row>
                  <Row className="g-3">
                    <Col>
                      <span className="main-titles">CO2</span>
                      <br />
                      <span className="sub-titles">{airScopeData?.co2 || 0}</span>
                    </Col>
                    <Col>
                      <span className="main-titles">03</span>
                      <br />
                      <span className="sub-titles">{airScopeData?.o3 || 0}</span>
                    </Col>
                    <Col>
                      <span className="main-titles">C0</span>
                      <br />
                      <span className="sub-titles">{airScopeData?.co || 0}</span>
                    </Col>
                    <Col>
                      <span className="main-titles">NO2</span>
                      <br />
                      <span className="sub-titles">{airScopeData?.no2 || 0}</span>
                    </Col>
                    <Col>
                      <span className="main-titles">Lux</span>
                      <br />
                      <span className="sub-titles">{airScopeData?.luxlevel || airScopeData?.lux || 0}</span>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={12} sm={12} className="mt-3">
                <div className="white-card-box">
                  <Row>
                    <Col lg={3} sm={3}>
                      <div style={{ background: "#F4F4F4" }} onClick={() => {
                        setModalShow(true)
                        setIsChartView(false)
                      }}>
                        <img
                          src={stationData?.stationImage || Fridgesimg}
                          // Fridgesimg
                          alt=""
                          width={"100%"}
                          style={{ padding: "10px", height: "160px", objectFit: "contain", width: '100%', }}
                        />
                      </div>
                    </Col>
                    <Col lg={9} sm={9} className="p-2">
                      <Row className="g-3 mb-2">
                        <Col lg={3} sm={3} xs={6}>
                          <span className="main-titles">Leaf Guardian</span>
                          <br />
                          <span className="sub-titles">{stationData?.leafGuardian || "-"}</span>
                        </Col>
                        <Col lg={3} sm={3} xs={6}>
                          <span className="main-titles">Last Water Filled</span>
                          <br />
                          <span className="sub-titles">{stationData?.lastWaterFilled || "-"}</span>
                        </Col>
                        <Col lg={3} sm={3} xs={6}>
                          <span className="main-titles">Station Mode</span>
                          <br />
                          <span className="sub-titles">{stationData?.stationMode || "-"}</span>
                        </Col>
                        <Col lg={3} sm={3} xs={6}>
                          <span className="main-titles">Location</span>
                          <br />
                          <span className="sub-titles">{stationData?.location || "-"}</span>
                        </Col>
                      </Row>
                      <Row className="g-3 mb-2">
                        <Col lg={3} sm={3} xs={6}>
                          <span className="main-titles">Last Service Date</span>
                          <br />
                          <span className="sub-titles">{stationData?.lastServiceDate ? moment(stationData?.lastServiceDate).format('MM-DD-YYYY') : "-"}</span>
                        </Col>
                        <Col lg={3} sm={3} xs={6}>
                          <span className="main-titles">Last Irrigation</span>
                          <br />
                          <span className="sub-titles">{stationData?.lastIrrigationDate || "-"}</span>
                        </Col>
                        <Col lg={3} sm={3} xs={6}>
                          <span className="main-titles">Timing</span>
                          <br />
                          <span className="sub-titles">{stationData?.timing || "-"}</span>
                        </Col>
                        <Col lg={3} sm={3} xs={6}>
                          <span className="main-titles">Tag</span>
                          <br />
                          <span className="sub-titles">{stationData?.tag || "-"}</span>
                        </Col>
                      </Row>
                      <Row className="g-3">
                        <Col lg={3} sm={3} xs={6}>
                          <span className="main-titles">Next Service Date</span>
                          <br />
                          <span className="sub-titles">{stationData?.nextServiceDate ? moment(stationData?.nextServiceDate).format('MM-DD-YYYY') : "-"}</span>
                        </Col>
                        <Col lg={3} sm={3} xs={6}>
                          <span className="main-titles">Irrigation Mode</span>
                          <br />
                          <span className="sub-titles">{stationData?.irrigationMode || "-"}</span>
                        </Col>
                        <Col lg={3} sm={3} xs={6}>
                          <span className="main-titles">Irrigation Timings</span>
                          <br />
                          <span className="sub-titles">{stationData?.irrigationTimings || "-"}</span>
                        </Col>
                        <Col lg={3} sm={3} xs={6}>
                          <span className="main-titles">Building</span>
                          <br />
                          <span className="sub-titles">{stationData?.building || "-"}</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row >

        <Row className="g-3 mt-0">
          <Col lg={6} sm={6} className="mb-3">
            <div className="white-card-box py-4">
              <Row>
                <Col lg={6} sm={6}>
                  <div className="boxshadow p-2 pb-0">
                    <center className=" mb-2">
                      <b className="font-16">(Indoor)</b>
                      <GaugeChart
                        id="gauge-chart3"
                        nrOfLevels={3}
                        colors={["green", "orange", "red"]}
                        arcWidth={0.05}
                        formatTextValue={() =>
                          gaugeChartIndoorData.length > 0
                            ? (gaugeChartIndoorData[indoorIndex]?.value / 100).toString()
                            : "0"
                        }
                        // percent={
                        //   gaugeChartIndoorData.length > 0
                        //     ? gaugeChartIndoorData[indoorIndex]?.value / 100
                        //     : 0
                        // }
                        percent={gaugeChartIndoorData.length > 0
                          ? getGaugeChartPercent((gaugeChartIndoorData[indoorIndex]?.value), stationParameterRangeData.find((param: any) => param.parameterName == gaugeChartIndoorData[indoorIndex]?.label))
                          : 0
                        }
                        textColor={"black"}
                      />
                      <strong className="mt-1">{formatLabel(gaugeChartIndoorData[indoorIndex]?.label)}</strong>
                      <div className="d-flex justify-content-centre">
                      </div>
                      {/* <div className="d-flex justify-content-between">
                        <div>
                          <b className="font-16">
                            Max <span className="text-success">80</span>
                          </b>
                        </div>
                        <div>

                          <b className="font-16">
                            Min <span className="text-success">20</span>
                          </b>
                        </div>
                        <div>
                          <b className="font-16">
                            Avg <span className="text-success">80</span>
                          </b>
                        </div>
                      </div> */}
                    </center>
                  </div>
                </Col>
                <Col lg={6} sm={6}>
                  <div className="boxshadow p-2 pb-0">
                    <center className="mb-2">
                      <b className="font-16">(Ambient)</b>
                      <GaugeChart
                        id="gauge-chart3"
                        nrOfLevels={3}
                        colors={["green", "orange", "red"]}
                        arcWidth={0.05}
                        textColor={"black"}
                        formatTextValue={() =>
                          gaugeChartOutdoorData.length > 0
                            ? (gaugeChartOutdoorData[outdoorIndex]?.value / 100).toString()
                            : "0"
                        }
                        // percent={
                        //   gaugeChartOutdoorData.length > 0
                        //     ? gaugeChartOutdoorData[outdoorIndex]?.value / 100
                        //     : 0
                        // }
                        percent={gaugeChartOutdoorData?.length > 0
                          ? getGaugeChartPercent((gaugeChartOutdoorData[outdoorIndex]?.value), stationParameterRangeData.find((param: any) => param?.parameterName == gaugeChartOutdoorData[outdoorIndex]?.label))
                          : 0
                        }
                      />

                      <strong className="mt-1">{formatOutdoorLabel(gaugeChartOutdoorData[outdoorIndex]?.label)}</strong>

                      {/* <div className="d-flex gap-2 justify-content-between">
                        <div>
                          <b className="font-16">
                            Max <span className="text-success">50</span>
                          </b>
                        </div>
                        <div>
                          <b className="font-16">
                            Min <span className="text-success">10</span>
                          </b>
                        </div>
                        <div>
                          <b className="font-16">
                            Avg <span className="text-success">40</span>
                          </b>
                        </div>
                      </div> */}
                    </center>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={4} sm={4}>
            <div className="white-card-box py-3">
              <div className="d-flex">
                <div className="col-2">
                  <select
                    className="form-select"
                    {...register("parameter")}
                    onChange={(event) => {
                      setValue("parameter", event.target.value)
                    }}>
                    <option value={"PM2.5"} selected>{"PM2.5"}</option>
                    <option value={"PM1"}>{"PM1"}</option>
                    <option value={"PM10"}>{"PM10"}</option>
                    <option value={"TVOC"}>{"TVOC"}</option>
                    <option value={"CO2"}>{"CO2"}</option>
                    <option value={"TEMP"}>{"TEMP"}</option>
                    <option value={"RH"}>{"RH"}</option>
                    <option value={"O2"}>{"O2"}</option>
                    <option value={"O3"}>{"O3"}</option>
                    <option value={"CO"}>{"CO"}</option>
                    <option value={"NO2"}>{"NO2"}</option>
                    <option value={"NH3"}>{"NH3"}</option>
                  </select>
                  <span style={{ display: "inline-block", width: "10px", height: "10px", backgroundColor: "#32CD32", marginRight: "5px" }}></span>
                  <span className="font-bold font-12">Indoor</span>
                  <br></br>
                  <span style={{ display: "inline-block", width: "10px", height: "10px", backgroundColor: "#FFA500", marginRight: "5px" }}></span>
                  <span className="font-bold font-10">Ambient</span>
                </div>
                <div className="col-1" onClick={() => {
                  setModalShow(true)
                  setIsChartView(true)
                }}>
                  <HiMiniViewfinderCircle size={20} />
                </div>
              </div>
              <div>
                <FullScreenChart
                  show={modalShow}
                  src={stationData?.stationImage || Fridgesimg}
                  outdoorOptionGraphData={outdoorOptionGraphData}
                  isChartView={isChartView}
                  onHide={() => setModalShow(false)}
                />
                <ReactECharts
                  option={outdoorOptionGraphData}
                  style={{ height: "110px", width: "100%" }}
                />
              </div>
              <div className="mt-1">
                <span className="text-success font-12 font-bold  mx-5">
                  Live
                </span>
              </div>
            </div>
          </Col>
          <Col lg={2} sm={2}>
            <div className="white-card-box py-4">
              <div
                className="boxshadow1 weatherbg p-3 h-100"
                style={{ height: "200px" }}
              >
                <div className="d-flex align-items-center my-2 h-100 row">
                  <Col lg={7} className="pe-0 d-flex align-items-center">
                    <div className="weather py-2">
                      <h5 className="mb-1 text">
                        {" "}
                        {weatherData && weatherData?.temperature}°C
                      </h5>
                      <p className="mb-0 font-400 font-12 text-dark">
                        Hello, {weatherData && weatherData?.city}!
                      </p>
                    </div>
                  </Col>
                  <Col lg={5} className="text-center">
                    <h5 className="mb-1">
                      <img src={Sun} alt="Logo" width="30" />
                    </h5>
                    <p className="font-12 mb-0 font-400">
                      {weatherData && weatherData?.weatherDiscription}
                    </p>
                  </Col>
                </div>
              </div>
              <div className="brderBtm"></div>
              <div className="d-flex justify-content-between aling-items-center">
                <div className="font-10">
                  <span className="font-bold">
                    Dominant
                    <br />
                    Pollutant
                  </span>{" "}
                  (Indoor)
                </div>
                <div>
                  <img src={OzoneLayero3} alt="" />{" "}
                  <span className="font-12" style={{ color: "#278C5D" }}>
                    ({indoorData && indoorData?.dominantPollutant})
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/*<Row className="g-3 mt-1">

           <Col lg={3} sm={3}>
            <div className="white-card-box">
              <div>
                <h5 className="h5-title">
                  <img src={Analytics} alt="" sizes="" /> Analytics
                </h5>
              </div>
              <ul className="li-list">
                <li>
                  <img src={LowG} alt="" sizes="16" /> Usually high at evening
                </li>
                <li>
                  <img src={LowR} alt="" sizes="16" /> Usually Low at Morning
                </li>
                <li>
                  <img src={LowG} alt="" sizes="16" /> Usually high on Monday
                </li>
                <li>
                  <img src={LowG} alt="" sizes="16" /> Station is usually High
                </li>
              </ul>
            </div>
          </Col>
        </Row> */}


      </div >
    </>
  );
};
export default CommandCentreNewFlow;
