import { Lungs } from "react-bootstrap-icons";

interface PropsData {
  webscocketIndoorData: any;
  indoorLiveData: any;
  fontColor: any;
  logo: any;
  pointer: any;
  unit: any;
}
const RecordSpan = (props: PropsData) => {

  return (
    <div className="boxes p-2">
      <div className="d-flex justify-content-start align-items-center">
        <div className="me-2">
          <img src={props?.logo} alt="" sizes="25" className="imgWidth" />
          {/* <Lungs color={props?.fontColor} size={25} /> */}
        </div>
        <div>
          <span style={{ color: props?.fontColor }}>
            {props?.webscocketIndoorData !== null &&
            props?.webscocketIndoorData !== undefined
              ? props?.webscocketIndoorData
              : props?.indoorLiveData}
          </span>
          <small className="small-text" style={{ color: props?.fontColor }}>
            {props?.unit}
          </small>
          <br />
          <span className="cityname" style={{ color: props?.fontColor }}>
            {props?.pointer}
          </span>
        </div>
      </div>
    </div>
  );
};
export default RecordSpan;
