import {MdOutlineKeyboardBackspace} from 'react-icons/md';
import { Link } from "react-router-dom";
interface PropData {
    Title: string
}
  const PageTitle = (props: PropData) => {
    const goBack = () => {
      window.history.back();
    };
    return (
      <>
        <div className="back-arrow">
          <div className="d-flex align-items-center gap-2">
            { (
              <Link onClick={goBack} to="" className="left-arrow mr-3 text-brand">
               <MdOutlineKeyboardBackspace  size={24}/>
              </Link>
            )}
            <div className="page-title">
              {props.Title}
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default PageTitle;