export type ProtectedRouteProps = {
  authenticationPath: string;
  outlet: JSX.Element;
};

export default function ProtectedRoute({
  authenticationPath,
  outlet,
}: ProtectedRouteProps) {
  if (localStorage.getItem("access_token") != null) {
      // var as = localStorage.getItem('uat');
      // if (as != null && window.atob(as) === "ADMIN") {
      //   if (window.location.pathname.includes('/manage-clients')) {
      //     return outlet;
      //   } else {
      //     window.location.href = "/manage-clients";
      //   }
      // }
    return outlet;
  } else {
    localStorage.clear();
    window.location.href ="/login";
    //  return outlet;
  }
}
