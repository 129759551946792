import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, InputGroup, Modal, Row, Table } from "react-bootstrap";
import { IoSearchOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WebService from "../../Services/WebService";
import TableLoader from "../Common/TableLoader/TableLoader";
import AsyncSelect from "react-select/async";
import FridgeIcon from "../../assets/images/fridged.svg";
import UsersIcon from "../../assets/images/users.svg";
import LocationIcon from "../../assets/images/locations.svg";
import FridgecheckIcon from "../../assets/images/fridgecheck.svg";
import FridgeSleepIcon from "../../assets/images/fridgesleep.svg";
import FridgeR from "../../assets/images/fridgeR.svg";
import FridgeG from "../../assets/images/fridgeG.svg";
import SellIcon from "../../assets/images/sell.svg";
import NotificationIcon from "../../assets/images/notification.svg";
import Air from "../../assets/images/air.svg";
import Lungs from "../../assets/images/lungs.svg";
import SettingIcon from "../../assets/images/setting.svg";
import GrowthIcon from "../../assets/images/growth.svg";
import RainSoil from "../../assets/images/rain-soil.svg";
import PlantPot from "../../assets/images/plant-pot.svg";
import starFill from "../../assets/images/pinfill.png";
import starUnFill from "../../assets/images/pinunfill.png";
import NoDataFound from "../../assets/images/empty_item.svg";
import { toast } from "react-toastify";
import moment from "moment";
import { useForm } from "react-hook-form";
import { MdClose } from "react-icons/md";
import { BsSave } from "react-icons/bs";
import { Label } from "../Common/Label/Label";

let allOptions: any[] = []
var key = 0;
interface Station {
  stationName: string;
  id: string;
  comapanyId: string;
  online: boolean;
  pin: boolean;
  outdoorSensorId: string
}
interface CommandCenterCounts {
  totalActive: number;
  totalClients: number;
  totalLocations: number;
  totalOffline: number;
  totalOnline: number;
  totalSleep: number;
  totalStations: number;
}
const CommandCentreNewFlow = (props: any) => {
  const { t } = useTranslation();
  const { register, formState: { errors }, setValue, handleSubmit, watch, reset, clearErrors } = useForm();
  const watchVariable = watch();
  const [commandCenterCounts, setCommandCenterCounts] =
    useState<CommandCenterCounts>({
      totalActive: 0,
      totalClients: 0,
      totalLocations: 0,
      totalOffline: 0,
      totalOnline: 0,
      totalSleep: 0,
      totalStations: 0,
    });
  const [keyword, setKeyword] = useState("");
  const [loader, setLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [stationList, setStationList] = useState<Station[]>([]);
  const [index, setIndex] = useState(0);
  const [sortBy, setSortBy] = useState("stationName");
  const [orderBy, setOrderBy] = useState("ASC");
  const [stationData, setStationData] = useState<any>(null);
  const [relayList, setRelayList] = useState<any[]>([])
  const [type, setType] = useState("")
  const [selectedClientId, setSelectedClientId] = useState<any>([]);
  const [selectedClient, setSelectedClient] = useState({
    value: "",
    label: "Select Client Name",
  });
  const [relayScreen, setRelayScreen] = useState(false);
  let navigate = useNavigate();
  const [selectedComponent, setSelectedComponent] = useState<any[]>([]);
  const participantRef = useRef(null);
  const participantRefRegulate = useRef(null);
  const [selectedParticipants, setSelectedParticipants] = useState<any[]>([]);
  const [selectedParticipantsRegulate, setSelectedParticipantsRegulate] = useState<any[]>([]);
  const [stationId, setStationId] = useState("");
  const [obj, setObj] = useState<any>({
    option1: [],
    option2: [],
    option3: [],
    option4: [],
    option5: [],
    option6: [],
    option7: [],
    option8: [],
    option9: [],
    option10: [],
    option11: [],
  });
  const [obj1, setObj1] = useState<any>({
    option1: [],
    option2: [],
    option3: [],
    option4: [],
    option5: [],
    option6: [],
    option7: [],
    option8: [],
    option9: [],
    option10: [],
    option11: [],
  });


  const getCommandCentreCount = () => {
    setLoader(true);
    return WebService.getAPI({
      action: `command-centre/count?company_id=${selectedClient.value}`,
      body: null,
      isShowError: true,
    })
      .then((res: any) => {
        setCommandCenterCounts(res?.result);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        return error;
      });
  };

  const getCommandCentre = (page: any, company_id: any, sortByPin?: any) => {
    if (index === 0) {
      setLoader(true);
    }
    var arr = [];
    const body = {
      page: page,
      order_by: sortByPin ? "DESC" : orderBy,
      sort_by: sortByPin ? sortByPin : sortBy,
      keyword: keyword,
      company_id: selectedClient.value,
    };
    var queryParam = new URLSearchParams(body);
    return WebService.getAPI({
      action: `command-centre/stations?` + queryParam,
      body: null,
      isShowError: true,
    })
      .then((res: any) => {
        if (index === 0) {
          setLoader(false);
          setIndex(1);
        }
        setStationList(res.list);
        setTotalCount(res.count);

        res.list.forEach((station: any) => {
          getWebsocketView(station);
          // company data
          props.socket.send(
            JSON.stringify({
              action: "subscribe",
              topic: `Devices/M2C/ST_${station.comapanyId}_${station.id}/stationData`,
            })
          );

          props.socket.onmessage = (event: any) => {
            let data = JSON.parse(event?.data);

            if (
              data.topic === `Devices/M2C/ST_${station.comapanyId}_${station.id}/stationData`
            ) {
              setStationData(JSON.parse(data?.content))
            }
          };
        });
      })
      .catch((error) => {
        setLoader(false);
        return error;
      });
  };

  const handlePinStation = (station_id: any, pin_status: boolean) => {
    let pin_status1 = !pin_status
    const body = {
      stationId: station_id,
      pin: pin_status1,
    };

    return WebService.putAPI({
      action: `command-centre/station/pin`,
      body: body,
      isShowError: true,
    })
      .then((res: any) => {
        // toast.success(res?.message);
        getCommandCentre(page, "", "pin");
      })
      .catch((error) => {
        setLoader(false);
        return error;
      });
  };
  const handleSwitch = (stationId: any, status: any) => {
    let obj = {
      stationId: stationId,
      online: !status,
    };
    return WebService.putAPI({
      action: `station/isonline`,
      body: obj,
      isShowError: true,
    })
      .then((res: any) => {
        toast.success(res?.message);
        // let tempStationList = [...stationList];
        // tempStationList[index].online = !status;
        // setStationList(tempStationList);
        getCommandCentreCount();
        setStationList((prevStationList) =>
          prevStationList.map((station: any, i) =>
            station?.id === stationId
              ? { ...station, online: !station.online }
              : station
          )
        );
      })
      .catch((error) => {
        toast.error(error.data.message);
      });
  };
  // const clientPromiseOptions = (inputValue: any) =>
  //   new Promise<any[]>((resolve) => {
  //     const body = {
  //       page: page.toString(),
  //       order_by: orderBy,
  //       sot_by: sortBy,
  //       keyword: keyword,
  //     };
  //     var queryParam = new URLSearchParams(body);
  //     return WebService.getAPI({
  //       action: `companies?` + queryParam,
  //       // action: `companies?=${inputValue}`,
  //       body: null,
  //       isShowError: true,
  //     })
  //       .then((res: any) => {
  //         if (res.list.length > 0) {
  //           var updatedOptions = res.list.map((companyName: any) => ({
  //             value: companyName.id,
  //             label: companyName.name,
  //           }));
  //           updatedOptions.unshift({
  //             value: "",
  //             label: "All",
  //           });
  //           resolve(updatedOptions);
  //         } else {
  //           resolve([]);
  //         }
  //       })
  //       .catch((error) => {
  //         resolve([]);
  //         return error;
  //       });
  //   });

  const clientPromiseOptions = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      var list: any = []
      if (watchVariable.site) {
        return false;
        list = WebService.getAPI({
          action: `&keyword=` + inputValue,
        })
          .then((res: any) => {
            if (res.result.length > 0) {
              var updatedOptions: any[] = res.result.map((clientId: any) => ({
                value: clientId.id,
                label: clientId.name,
              }))
              updatedOptions.unshift({
                value: "ALLCLIENT",
                label: "Select All"
              })
              // setValue("MClist", updatedOptions);
              allOptions = updatedOptions
              return updatedOptions;
            } else {
              return [];
            }
          })
          .catch((error) => {
            return [];
          })
      } else {
        list = []
      }
      resolve(list);
    })

  // const onClientSelection = (selectedOption: any) => {
  //   setSelectedClient(selectedOption);
  // };

  const onClientSelection = (selectedOption: any) => {
    var arr: any = [];
    var flag = false;
    var clientList: any = [];

    if (selectedOption && selectedOption.length > 0) {
      flag = selectedOption.some((ele: any) => ele.value === "ALLCLIENT")
      if (flag) {
        arr = allOptions.filter((ele: any) => ele.value !== "ALLCLIENT")
      } else {
        arr = selectedOption
      }
    }
    setSelectedClientId(arr);
    arr.map((value: any) => clientList.push(value.value))
    watchVariable.clientId = clientList
    setValue("clientId", clientList)
  }

  const getWebsocketView = (station: any) => {
    props.socket.onmessage = function (event: any) {
      let data = JSON.parse(event.data);

      let topic = data.topic;
      let content = data.content;
    };
  };

  const HandleRelay = (id: any) => {
    setStationId(id);
    setRelayScreen(true)
    return WebService.getAPI({
      action: `relays?page=1&order_by=ASC&sort_by=id&station_id=${id}`,
      body: null,
      isShowError: true
    }).then((res: any) => {
      key = key + 1;
      setLoader(false)
      setRelayList(res.list)
      setTotalCount(res.count)
    }).catch((error) => {
      setLoader(false)
      return error
    })
  }

  useEffect(() => {
    if (relayScreen) {
      const intervalId = setInterval(() => {
        HandleRelay(stationId);
      }, 30000);

      return () => clearInterval(intervalId);
    }
  }, [relayScreen]);

  const handleSave = async (e: any) => {
    e.preventDefault();
    const payload = transformDataForApi();
    await saveRelayComponent1(payload);
    console.log("payload", payload);
  };

  const transformDataForApi = () => {
    // Transform relayList to the desired structure
    return {
      stationId: stationId, // Adjust if needed
      relayRequests: relayList.map(item => ({
        id: item.id,
        relayValue: item.relayValue,
        relayCode: item.relayCode // Adjust based on your data
      }))
    };
  };

  const saveRelayComponent1 = async (payload: any) => {
    try {

      const transformedComponents = selectedComponent.map((ele: any) => ({
        id: ele.value,
        label: ele.label
      }));

      console.log(transformedComponents);
      setSelectedComponent(transformedComponents);

      setLoader(true);
      WebService.addLoader("saveRelay");
      const body = {
        stationId: stationId,
        relayRequests: relayList.map((list: any) => ({
          id: list.id,
          relayValue: list.relayValue,
          relayCode: list.relayCode,
          enabled: list.enabled,
          working: list.working,
        }))
      };
      await WebService.putAPI({
        action: `relay/list`,
        body: body,
        isShowError: true
      }).then((res: any) => {
        setLoader(false)
        WebService.removeLoader("saveRelay")
        toast.success(res.message)
      }).catch((error) => {
        setLoader(false)
        WebService.removeLoader("saveRelay")
        return error
      })
    } catch (error) {
      setLoader(false);
      WebService.removeLoader("saveRelay");
      console.error("Error saving relay component:", error);
      return error;
    }
  };

  const onEnabledSelect = (value: any, id: any) => {
    const updatedRelayList = [...relayList]
    updatedRelayList.map((itm: any) => {
      if (itm.id === id) {
        itm.enabled = value
      }
    })
    setRelayList(updatedRelayList);
  }

  const handleInputChange = (value: any, id: any) => {
    // Update state with new relay values
    setRelayList(prevList =>
      prevList.map(item =>
        item.id === id ? { ...item, relayValue: value } : item
      )
    );
  };

  const onWorkingSelect = (value: any, id: any) => {
    const updatedRelayList = [...relayList]
    updatedRelayList.map((itm: any) => {
      if (itm.id === id) {
        itm.working = value
      }
    })
    setRelayList(updatedRelayList);
  }

  const getColorClass = (value: any) => {
    switch (value) {
      case 1:
        return 'green';
      case 0:
        return 'grey';
      case -1:
        return 'red';
      default:
        return '';
    }
  };

  const onParticipantSelectionRegulate = (selectedOption: any, index: any, id: any) => {
    // if(relayName === "relay1Regulate" || relayName === "relay2Regulate")
    const updatedSelections = [...selectedParticipantsRegulate];
    if (selectedOption === null) {
      updatedSelections[index] = null;
    } else {
      updatedSelections[index] = selectedOption;
    }
    setSelectedParticipantsRegulate(updatedSelections);

    const updatedSelections1 = [...relayList];
    updatedSelections1.map((itm) => {
      if (itm.id === id) {
        itm.relayCode = selectedOption ? selectedOption.value : null
      }
    })
    setRelayList(updatedSelections1)
  };

  const getAvailableOptions = (index: any) => {
    const selectedValues = selectedParticipants.map(option => option?.value);

    return obj[`option${index + 1}`]?.filter(
      (option: any) => !selectedValues.includes(option.value)
    );
  };

  const getAvailableOptionsRegulate = (index: any) => {
    const selectedValues = selectedParticipantsRegulate.map(option => option?.value);

    return obj1[`option${index + 1}`]?.filter(
      (option: any) => !selectedValues.includes(option.value)
    );
  };

  const participantPromiseOptions = () =>
    new Promise<any[]>((resolve) => {
      return WebService.getAPI({
        action: `relay/component?order_by=DESC&sort_by=id&keyword`,
      })
        .then((res: any) => {
          if (res?.list?.length > 0) {
            let pagesOptions: any[] = [];
            res.list.map((relayCode: any, page: any) => {
              var object = {
                //   "value": options,
                //   "label": options
                value: relayCode.relayCode,
                label: relayCode.relayCode,
              }
              pagesOptions.push(object);
            });
            let obj = {
              option1: pagesOptions,
              option2: pagesOptions,
              option3: pagesOptions,
              option4: pagesOptions,
              option5: pagesOptions,
              option6: pagesOptions,
              option7: pagesOptions,
              option8: pagesOptions,
              option9: pagesOptions,
              option10: pagesOptions,
              option11: pagesOptions,
            }
            setObj(obj)
          }
        })
        .catch((error: any) => {
          return error;
        });
    });
  const onParticipantSelection = (selectedOption: any, index: any, id: any) => {
    // if(relayName === "relay1Regulate" || relayName === "relay2Regulate")
    const updatedSelections = [...selectedParticipants];
    if (selectedOption === null) {
      updatedSelections[index] = null;
    } else {
      updatedSelections[index] = selectedOption;
    }
    setSelectedParticipants(updatedSelections);

    const updatedSelections1 = [...relayList];
    updatedSelections1.map((itm) => {
      if (itm.id === id) {
        itm.relayCode = selectedOption ? selectedOption.value : null
      }
    })
    setRelayList(updatedSelections1)
  };


  const participantPromiseOptionsRegulate = () => {
    return WebService.getAPI({
      action: `input/relay/component?`,
    })
      .then((res: any) => {
        if (res?.list?.length > 0) {
          let pagesOptions: any[] = [];
          res.list.map((relayCode: any, page: any) => {
            var object = {
              //   "value": options,
              //   "label": options
              value: relayCode.relayCode,
              label: relayCode.relayCode,
            }
            pagesOptions.push(object);
          });
          let obj1 = {
            option1: pagesOptions,
            option2: pagesOptions,
            option3: pagesOptions,
            option4: pagesOptions,
            option5: pagesOptions,
            option6: pagesOptions,
            option7: pagesOptions,
            option8: pagesOptions,
            option9: pagesOptions,
            option10: pagesOptions,
            option11: pagesOptions,
          }
          setObj1(obj1)
        }
      })
      .catch((error: any) => {
        return error;
      });
  };

  const handleStationMode = (mode: any) => {
    return WebService.getAPI({
      action: `command-centre/stations?company_id=&order_by=DESC&sort_by=pin&mode=${mode}`,
      body: null,
      isShowError: true
    }).then((res: any) => {
      setStationList(res.list);
    }).catch((error: any) => {
      return false;
    })
  }

  useEffect(() => {
    let role = localStorage.getItem("role");
    if (role === "MANAGER" || role === "CLIENT") {
      navigate("/access-denied");
    }
    if (index === 0) {
      getCommandCentre(page, selectedClient?.value, "pin");
    }
    getCommandCentreCount();
    getCommandCentre(page, selectedClient?.value, "pin");
    participantPromiseOptions();
    participantPromiseOptionsRegulate();
    const intervalId = setInterval(() => {
      getCommandCentre(page, "", "pin");
    }, 30000);

    return () => clearInterval(intervalId);
  }, [page, sortBy, orderBy, keyword, selectedClient]);

  return (
    <>
      <Row>
        <Col>
          <div className="justify-content-between align items-center mb-3">
            <h5 className="mt-2 mr-3">
              <b>Manage Command Centre</b>
            </h5>
          </div>
        </Col>
        <Col md={3}>
          <div className="form-group">
            <AsyncSelect
              id="select_client"
              value={selectedClient}
              // value = {selectedClientId}
              cacheOptions
              isMulti
              defaultOptions
              onChange={onClientSelection}
              loadOptions={clientPromiseOptions}
              placeholder={"Select Client"}
            />
          </div>
        </Col>
        <Col md={3}>
          <select className="form-select"
            onChange={(e) => {
              setType(e.target.value)
            }}>
            <option value={""} selected disabled>Select type</option>
            <option value={"ULTRA"}>Ultra</option>
            <option value={"ULTRAPRO"}>Ultrapro</option>
            <option value={"VANILLA"}>Vanilla</option>
            <option value={"ULTIMATE"}>Ultimate</option>
          </select>
        </Col>
        <Col md={3} className="d-flex justify-content-end gap-3 mb-3">
          <InputGroup className="table-search-box border">
            <Form.Control
              placeholder={t("Search_for_anything")}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter") {
                  setKeyword(e.currentTarget.value);
                }
              }}
            />
            <InputGroup.Text id="basic-addon1">
              <IoSearchOutline className="icon" />
            </InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>

      <div className="commandCentre-page">
        {" "}
        {/* table-card card-boxes */}
        <Row className="g-3">
          <Col lg={9} sm={9}>
            <div className="g-3 mb-3 d-flex align-items-center justify-content-between">
              <Col className="me-2">
                <div className="white-card-box">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col me-1">
                      <img src={FridgeIcon} alt="" className="margin-mins" />
                      <span className="main-title">
                        {commandCenterCounts?.totalStations}
                      </span>
                      <br />
                      <span className="sub-title">Total JBS</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="me-2">
                <div className="white-card-box">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col me-1">
                      <img src={UsersIcon} alt="" className="margin-mins" />
                      <span className="main-title">
                        {commandCenterCounts?.totalClients}
                      </span>
                      <br />
                      <span className="sub-title">Clients</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="me-2">
                <div className="white-card-box">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col me-1">
                      <img src={LocationIcon} alt="" className="margin-mins" />
                      <span className="main-title">
                        {commandCenterCounts?.totalLocations}
                      </span>
                      <br />
                      <span className="sub-title">Locations</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="me-2">
                <div className="white-card-box">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col" onClick={() => handleStationMode("active")} style={{ cursor: 'pointer' }}>
                      <img
                        src={FridgecheckIcon}
                        alt=""
                        className="margin-mins"
                      />
                      <span className="main-title">
                        {commandCenterCounts?.totalActive}
                      </span>
                      <br />
                      <span className="sub-title">Active</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="me-2">
                <div className="white-card-box">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col me-1" onClick={() => handleStationMode("sleep")} style={{ cursor: 'pointer' }}>
                      <img
                        src={FridgeSleepIcon}
                        alt=""
                        className="margin-mins"
                      />
                      <span className="main-title">
                        {commandCenterCounts?.totalSleep}
                      </span>
                      <br />
                      <span className="sub-title">Sleep</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="me-2">
                <div className="white-card-box greenBorder">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col me-1" onClick={() => handleStationMode("online")} style={{ cursor: 'pointer' }}>
                      <img src={FridgeG} alt="" className="margin-mins" />
                      <span className="main-title">
                        {commandCenterCounts?.totalOnline}
                      </span>
                      <br />
                      <span className="sub-title">Online</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="white-card-box redBorder">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col me-1" onClick={() => handleStationMode("offline")} style={{ cursor: 'pointer' }}>
                      <img src={FridgeR} alt="" className="margin-mins" />
                      <span className="main-title">
                        {commandCenterCounts?.totalOffline}
                      </span>
                      <br />
                      <span className="sub-title">Offline</span>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
            <div style={{ overflowY: 'scroll', overflowX: 'hidden', height: '680px' }}>
              <Row className="g-3">
                {stationList &&
                  stationList?.length > 0 &&
                  stationList?.map((data: any, index: any) => (

                    <Col lg={3} sm={3} key={index}>
                      <div className="white-card-box position-relative px-0 pb-0 pt-0">
                        <div className="d-flex align-items-center justify-content-start px-2">
                          <div className="me-2">
                            <img src={data.companyLogo} alt="" style={{ height: "60px", width: "60px", objectFit: 'contain' }} />
                          </div>
                          <div className="me-3 w-75">
                            <div className="label-title">
                              {data?.companyName} {data?.machineId}
                            </div>
                            {/* <br /> */}
                            <span className="cityname">
                              {data?.stationLocation}
                            </span>
                          </div>
                          <div className="img-position position-absolute" style={{ top: '14px' }}>
                            {/* <span className="divider"></span>
                          <span className="cityname">AQI</span> */}
                            {/* <br /> */}
                            {/* <span className="excellent">Excellent</span> */}

                            <span className="ms-2" onClick={() => { handlePinStation(data.id, data.pin) }}>
                              <img
                                src={data.pin ? starFill : starUnFill}
                                alt=""
                                style={{ height: "20px", width: "20px" }}
                              />
                              <span className="count">1</span>
                            </span>
                          </div>
                        </div>
                        <div className="borders-top">&nbsp;</div>
                        {/* <div className="d-flex align-items-center justify-content-center"> */}
                        {/* <div className="borderCenter">&nbsp;</div> */}
                        <div className="h-100 py-2 d-flex align-items-center justify-content-between">
                          <Col className="my-auto px-1 text-center">
                            <img
                              src={Lungs}
                              alt=""
                              sizes="25"
                              className="imgWidth"
                            />
                          </Col>
                          <Col className="my-auto px-0 text-center ">
                            <div className=""> {/* width80 */}
                              <span className="label-title-green">
                                {stationData?.pm2_5 || 0}
                              </span>
                              <small className="small-text">µg/m³</small>
                              <br />
                              <span className="cityname d-none">PM 2.5</span>
                            </div>
                          </Col>
                          <Col className="my-auto px-1 text-center">
                            <img
                              src={RainSoil}
                              alt=""
                              sizes="25"
                              className=""
                              style={{ width: '20px' }}
                            />
                          </Col>
                          <Col className="my-auto px-1 text-center">
                            <div className=""> {/* width80 */}
                              <span className="label-title-green">
                                {stationData?.moistureLevel || 0}
                              </span>
                              <small className="small-text">%</small>
                              <br />
                              <span className="cityname d-none">Moisture Level</span>
                            </div>
                          </Col>
                          <Col className="my-auto px-1 text-center">
                            <img
                              src={PlantPot}
                              alt=""
                              sizes="25"
                              className=""
                              style={{ width: '20px' }}
                            />
                          </Col>
                          <Col className="my-auto ps-0 text-center ">
                            <div className=""> {/*width80*/}
                              <span className="label-title-green">

                                {stationData?.waterLevel || 0}
                              </span>
                              <small className="small-text">%</small>
                              <br />
                              <span className="cityname d-none">Water Level</span>
                            </div>
                          </Col>
                        </div>
                        {/* </div> */}
                        <div className="borders-btm">&nbsp;</div>
                        <div className="gery-bg">
                          <div className="d-flex align-items-center justify-content-between py-2 px-2">
                            <div className="label-title font-14">
                              NSS :{" "}
                              {data?.nextServiceDate !== null
                                ? moment(data?.nextServiceDate).format(
                                  "DD-MM-YYYY"
                                )
                                : ""}
                            </div>
                            <div className="cityname">
                              LSD : {" "}
                              <span>
                                {data?.completedDate !== null
                                  ? moment(data?.completedDate).format(
                                    "DD-MM-YYYY"
                                  )
                                  : ""}
                              </span>
                              <br />
                              <span>LG- {data?.leafGuardianName}</span>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between py-2 px-3">
                          <div>
                            <img src={SettingIcon} alt="" sizes="25" onClick={() => {
                              HandleRelay(data.id)
                              setStationId(data.id);
                            }} />
                          </div>
                          <div>
                            {
                              data.powerSupply == "0" ?
                                <span style={{
                                  display: "inline-block",
                                  width: "20px",
                                  height: "20px",
                                  backgroundColor: "red",
                                  marginRight: "5px",
                                  borderRadius: "50%"
                                }}></span>
                                :
                                <span style={{
                                  display: "inline-block",
                                  width: "20px",
                                  height: "20px",
                                  backgroundColor: "green",
                                  marginRight: "5px",
                                  borderRadius: "50%"
                                }}></span>
                            }
                          </div>

                          <div>
                            <img src={GrowthIcon} alt="" sizes="25"
                              onClick={() =>
                                navigate(
                                  `/command-centre-new-flow-details/${data?.id}/${data?.comapanyId}/${data?.outdoorSensorId}`, { state: data }
                                )
                              }
                            />
                          </div>
                          <div>
                            <div className="form-check form-switch form-switch-md mb-0">
                              <input
                                className="form-check-input mt-0 cursor-pointer"
                                type="checkbox"
                                id="flexSwitchCheckDefault"
                                checked={data?.online}
                                onChange={() => {
                                  handleSwitch(data?.id, data?.online);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexSwitchCheckDefault"
                              ></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                  ))}
              </Row>
            </div>
          </Col>
          <Col lg={3} sm={3}>
            <div className="white-card-box">
              <h5 className="h5title text-center">Extra Care Unit</h5>
              {
                stationList &&
                stationList?.length > 0 &&
                stationList?.map((data: any, index: any) => {
                  return (
                    data?.pin === true ?
                      <Row className="g-3">
                        <Col lg={12} sm={12} className="mt-4">
                          <div className="position-relative px-0 pb-0"> {/* white-card-box  */}
                            <div className="d-flex align-items-center justify-content-start px-3">
                              <div className="me-2">
                                <img src={data.companyLogo} alt="" style={{ height: "60px", width: "60px", objectFit: 'contain' }} />
                              </div>
                              <div className="me-3 w-75">
                                <div className="label-title">
                                  {data?.companyName} {data?.machineId}
                                </div>
                                {/* <br /> */}
                                <span className="cityname">
                                  {data?.stationLocation}
                                </span>
                              </div>
                              <div className="img-position position-absolute" style={{ top: '14px' }}>
                                {/* <span className="divider"></span>
                          <span className="cityname">AQI</span> */}
                                {/* <br /> */}
                                {/* <span className="excellent">Excellent</span> */}

                                <span className="ms-2" onClick={() => { handlePinStation(data.id, data.pin) }}>
                                  <img
                                    src={data.pin ? starFill : starUnFill}
                                    alt=""
                                    style={{ height: "20px", width: "20px" }}
                                  />
                                  <span className="count">1</span>
                                </span>
                              </div>
                            </div>
                            <div className="borders-top">&nbsp;</div>
                            <div className="d-flex align-items-center justify-content-center">
                              <div className="borderCenter">&nbsp;</div>
                              <Row className="h-100" style={{ display: "flex" }}>
                                <Col lg={2} className="my-auto px-0">
                                  <img
                                    src={Lungs}
                                    alt=""
                                    sizes="25"
                                    className="imgWidth"
                                  />
                                </Col>
                                <Col lg={2} className="my-auto">
                                  <div className="width80">
                                    <span className="label-title-green">
                                      {stationData?.pm2_5 || 0}
                                    </span>
                                    <small className="small-text">µg/m³</small>
                                    <br />
                                    <span className="cityname d-none">PM 2.5</span>
                                  </div>
                                </Col>
                                <Col lg={2} className="my-auto px-0">
                                  <img
                                    src={RainSoil}
                                    alt=""
                                    sizes="25"
                                    className="imgWidth"
                                  />
                                </Col>
                                <Col lg={2} className="my-auto">
                                  <div className="width80">
                                    <span className="label-title-green">
                                      {stationData?.moistureLevel || 0}
                                    </span>
                                    <small className="small-text">%</small>
                                    <br />
                                    <span className="cityname d-none">Moisture Level</span>
                                  </div>
                                </Col>
                                <Col lg={2} className="my-auto px-0">
                                  <img
                                    src={PlantPot}
                                    alt=""
                                    sizes="25"
                                    className="imgWidth"
                                  />
                                </Col>
                                <Col lg={2} className="my-auto">
                                  <div className="width80">
                                    <span className="label-title-green">

                                      {stationData?.waterLevel || 0}
                                    </span>
                                    <small className="small-text">%</small>
                                    <br />
                                    <span className="cityname d-none">Water Level</span>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <div className="borders-btm">&nbsp;</div>
                            <div className="gery-bg">
                              <div className="d-flex align-items-center justify-content-between py-2 px-3">
                                <div className="label-title">
                                  NSS :{" "}
                                  {data?.nextServiceDate !== null
                                    ? moment(data?.nextServiceDate).format(
                                      "DD-MM-YYYY"
                                    )
                                    : ""}
                                </div>
                                <div className="cityname">
                                  LSD : {" "}
                                  <span>
                                    {data?.completedDate !== null
                                      ? moment(data?.completedDate).format(
                                        "DD-MM-YYYY"
                                      )
                                      : ""}
                                  </span>
                                  <br />
                                  <span>LG- {data?.leafGuardianName}</span>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between py-2 px-3">
                              <div>
                                <img src={SettingIcon} alt="" sizes="25" onClick={() => {
                                  HandleRelay(data.id)
                                  setStationId(data.id);
                                }} />
                              </div>
                              <div>
                                {
                                  data.powerSupply == "0" ?
                                    <span style={{
                                      display: "inline-block",
                                      width: "20px",
                                      height: "20px",
                                      backgroundColor: "red",
                                      marginRight: "5px",
                                      borderRadius: "50%"
                                    }}></span>
                                    :
                                    <span style={{
                                      display: "inline-block",
                                      width: "20px",
                                      height: "20px",
                                      backgroundColor: "green",
                                      marginRight: "5px",
                                      borderRadius: "50%"
                                    }}></span>
                                }
                              </div>

                              <div>
                                <img src={GrowthIcon} alt="" sizes="25"
                                  onClick={() =>
                                    navigate(
                                      `/command-centre-new-flow-details/${data?.id}/${data?.comapanyId}/${data?.outdoorSensorId}`, { state: data }
                                    )
                                  }
                                />
                              </div>
                              <div>
                                <div className="form-check form-switch form-switch-md mb-0">
                                  <input
                                    className="form-check-input mt-0 cursor-pointer"
                                    type="checkbox"
                                    id="flexSwitchCheckDefault"
                                    checked={data?.online}
                                    onChange={() => {
                                      handleSwitch(data?.id, data?.online);
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexSwitchCheckDefault"
                                  ></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      :
                      ""
                  )
                })
              }
            </div>
          </Col>
          {
            relayScreen && (
              <Modal
                show={relayScreen}
                onHide={() => setRelayScreen(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    {t('Relay')}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: "500px", overflowY: "scroll" }}>
                  <Form className="wmx-form-style" name="relay" id="relay" onSubmit={handleSave}>
                    <div className="table-card table-responsive">
                      <Card className="table-container">
                        <Table className="table-style">
                          <thead>
                            <tr>
                              <th style={{ cursor: "pointer" }}>
                                Relay
                              </th>
                              <th>
                                Flow Enabled
                              </th>
                              <th>
                                Manual Triggered
                              </th>
                              <th>Machine Status</th>
                              <th>Relay Component</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              relayList?.length > 0
                                ?
                                relayList?.map((list: any, index: any) => {
                                  return (
                                    <tr key={index}>
                                      <td>{list?.relayName}</td>
                                      <td>
                                        <div>
                                          <Form.Check
                                            type="switch"
                                            id={list?.id}
                                            defaultChecked={list?.enabled === true}
                                            className="labe-text-dark checkbox-success"
                                            onChange={(e: any) => {
                                              onEnabledSelect(e.target.checked, list?.id)
                                            }}
                                          />
                                        </div>
                                      </td>

                                      <td>
                                        {
                                          list.relayName === "relay1Regulate" || list.relayName === "relay2Regulate" ?
                                            <div>
                                              <input
                                                style={{ width: "70px" }}
                                                type="number"
                                                value={list.relayValue || ''}
                                                onChange={(e) => handleInputChange(e.target.value, list?.id)}
                                              />
                                            </div>
                                            :
                                            !list.relayName.includes("moisture") &&
                                              !list.relayName.includes("waterLevel") &&
                                              !list.relayName.includes("pms7003") &&
                                              !list.relayName.includes("dht22") &&
                                              !list.relayName.includes("lux") &&
                                              !list.relayName.includes("db") &&
                                              !list.relayName.includes("powerMonitor") &&
                                              !list.relayName.includes("relayBoard") ?
                                              <div>
                                                <Form.Check
                                                  type="switch"
                                                  id={list?.id}
                                                  defaultChecked={list?.working === true}
                                                  className="labe-text-dark checkbox-success"
                                                  onChange={(e) => onWorkingSelect(e.target.checked, list?.id)}
                                                />
                                              </div>
                                              : null
                                        }
                                      </td>

                                      <td>
                                        {
                                          list.relayName === "relay10" || list.relayName === "relay9" ? "" : <div className={`circle ${getColorClass(list?.machineStatus)}`} />
                                        }
                                      </td>
                                      <td>
                                        {
                                          (list.relayName.startsWith("relay") && !list.relayName.includes("Board") && !list.relayName.includes("moisture") && !list.relayName.includes("waterLevel") && !list.relayName.includes("pms7003") && !list.relayName.includes("dht22") && !list.relayName.includes("lux") && !list.relayName.includes("db") && !list.relayName.includes("powerMonitor"))
                                            ? (
                                              list.relayName.includes("Regulate")
                                                ? (
                                                  <div className="form-group">
                                                    <AsyncSelect
                                                      key={index}
                                                      ref={participantRefRegulate}
                                                      defaultOptions={getAvailableOptionsRegulate(index)}
                                                      cacheOptions
                                                      loadOptions={participantPromiseOptionsRegulate}
                                                      onChange={(selectedOption) => onParticipantSelectionRegulate(selectedOption, index, list.id)}
                                                      isSearchable={false}
                                                      placeholder="Select Type"
                                                      value={selectedParticipantsRegulate[index]}
                                                      isClearable={true}
                                                      defaultValue={list.relayCode ? { value: list.relayCode, label: list.relayCode } : ""}
                                                      isDisabled={list.relayName === "relay10" || list.relayName === "relay9"}
                                                    />
                                                  </div>
                                                ) : (
                                                  <div className="form-group">
                                                    <AsyncSelect
                                                      key={"relay_" + index}
                                                      ref={participantRef}
                                                      defaultOptions={getAvailableOptions(index)}
                                                      cacheOptions
                                                      loadOptions={participantPromiseOptions}
                                                      onChange={(selectedOption) => onParticipantSelection(selectedOption, index, list.id)}
                                                      isSearchable={false}
                                                      placeholder="Select Type"
                                                      value={selectedParticipants[index]}
                                                      isClearable={true}
                                                      defaultValue={list.relayCode ? { value: list.relayCode, label: list.relayCode } : ""}
                                                      isDisabled={list.relayName === "relay10" || list.relayName === "relay9"}
                                                    />
                                                  </div>
                                                )
                                            )
                                            : ""
                                        }
                                      </td>
                                    </tr>
                                  )
                                })
                                :
                                <tr>
                                  <td></td>
                                  <td>
                                    <div className="table-wrap position-relative text-centre">
                                      <div className="no-data-flound">
                                        <div className="" >
                                          <img src={NoDataFound} width={110} className="mb-3 text-centre" />
                                          <h3 className="text-secondary font-12">
                                            No Record Found
                                          </h3>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td></td>
                                </tr>
                            }
                          </tbody>
                        </Table>
                      </Card>
                    </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setRelayScreen(false)}>
                    <MdClose size={18} className="icon" />&nbsp; {t('cancel')}
                  </Button>
                  <Button variant="primary" type="submit" id="saveRelay">
                    <BsSave size={18} className="icon" />&nbsp; {t('save')}
                  </Button>
                </Modal.Footer>
              </Modal>

            )
          }
        </Row>
      </div>
    </>
  );
};
export default CommandCentreNewFlow;
