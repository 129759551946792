import React, { useEffect, useState } from "react";
import { Button, Card, Col, FloatingLabel, Form, InputGroup, Offcanvas, Row, Table } from "react-bootstrap";
import { IoSearchOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";
import { MdAddCircleOutline, MdClose, MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp, MdOutlineModeEdit } from "react-icons/md";
import { PiTrash } from "react-icons/pi";
import DeleteModal from "../../Common/DeleteModal/DeleteModal";
import AIPagination from "../../Common/Pagination/AIPagination";
import { useForm } from "react-hook-form";
import { Label } from "../../Common/Label/Label";
import { BsSave } from "react-icons/bs";
import PageTitle from "../../PageTitle";
import WebService from "../../../Services/WebService";
import { toast } from "react-toastify";
import NoDataFound from "../../../assets/images/empty_item.svg";
import FullPageLoader from "../../Common/FullPageLoader/FullPageLoader";
import TableLoader from "../../Common/TableLoader/TableLoader";
import { TooltipCustom } from "../../Common/Tooltip/Tooltip";
import HelperService from "../../../Services/HelperService";
import moment from "moment";
import AsyncSelect from "react-select/async";
import { useNavigate } from "react-router-dom";

var keywordIndex = 0;
const AddKYC = () => {
    const { t } = useTranslation();
    const [keyword, setKeyword] = useState("");
    const [showDelete, setShowDelete] = useState(false);
    const [action, setAction] = useState("");
    const [loader, setLoader] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(1);
    const [showKYC, setSetupShowKYC] = useState(false);
    const [sortBy, setSortBy] = useState("createdDate");
    const [orderBy, setOrderBy] = useState("DESC");
    const { register, setValue, clearErrors, formState: { errors }, handleSubmit, watch, reset } = useForm();
    const watchVariable = watch();
    const [list, setList] = useState<any[]>([]);
    const [id, setId] = useState("");
    const [clickable, setClickable] = useState("createdDate");
    const [keywordArr, setKeywordArr] = useState<any>([]);
    const [parameterFlag, setParameterFlag] = useState(false)
    const [selectedParameter, setSelectedParameter] = useState<any>([]);
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        getKYC(page, keyword);
        let role = localStorage.getItem("role");
        if (role === "MANAGER" || role === "CLIENT") {
            navigate("/access-denied")
        }
    }, [page, sortBy, orderBy])


    useEffect(() => {
        var arr = [...keywordArr];
        var obj = {
            label: "",
            index: keywordIndex
        }
        arr.push(obj);
        setKeywordArr(arr);
    }, [])


    const deleteList = (isDelete: any) => {
        if (isDelete) {
            if ((totalCount - 1) % 10 == 0) {
                getKYC(page - 1, keyword)
            } else {
                getKYC(page, keyword)
            }
        }
        setShowDelete(false);
    }

    const handleEditClose = () => {
        setSetupShowKYC(false);
        setId('')
        reset();
    };

    const saveQuestion = () => {
        var arr: any[] = [];

        selectedParameter.map((ele: any) => {
            var obj = {
                "id": ele.value,
                "label": ele.label
            }
            arr.push(obj);
        })
        console.log();

        setSelectedParameter(arr);

        var arr2: any[] = [];

        var arr1: any[] = [];
        keywordArr.map((ele: any) => {
            var obj = {
                id: ele.index,
                label: ele.label
            }
            arr1.push(obj);
        })
        console.log(arr1);
        setKeywordArr(arr1)

        // return false;
        if (id == "" || id == null || id == undefined) {
            setLoader(true)
            WebService.addLoader('savequestion')
            const body = {
                question: watchVariable.question,
                dataType: watchVariable.type,
                priority: parseInt(watchVariable.priority),
                kycQuestionOptionRequest: watchVariable.type == "CHECKLIST" ? arr : watchVariable.type == "DROPDOWN" ? arr1 : arr2,
                isMandatory : isChecked
            };

            return WebService.postAPI({
                action: `kyc-question`,
                body: body,
                isShowError: true,
            })
                .then((res: any) => {
                    toast.success(res.message);
                    getKYC(page, keyword)
                    setLoader(false)
                    WebService.removeLoader('savequestion')
                    reset();
                    setSetupShowKYC(false);
                })
                .catch((error) => {
                    setLoader(false)
                    WebService.removeLoader('savequestion')
                    return error;
                });

        } else {
            setLoader(true)
            WebService.addLoader('savequestion')
            const body = {
                id: id,
                question: watchVariable.question,
                dataType: watchVariable.type,
                priority: parseInt(watchVariable.priority),
                kycQuestionOptionRequest: watchVariable.type == "CHECKLIST" ? arr : watchVariable.type == "DROPDOWN" ? arr1 : arr2,
                isMandatory : isChecked
            };
            return WebService.putAPI({
                action: `kyc-question`,
                body: body,
                isShowError: true,
            })
                .then((res: any) => {
                    toast.success(res.message);
                    getKYC(page, keyword)
                    WebService.removeLoader('savequestion')
                    setLoader(false)
                    reset();
                    setSetupShowKYC(false);
                })
                .catch((error) => {
                    setLoader(false)
                    WebService.removeLoader('savequestion')
                    return error;
                });

        }
    }

    const getKYC = (page: any, keyword?: any) => {
        setLoader(true)
        WebService.addLoader('savequestion')
        const body = {
            page: keyword == "" ? page.toString() : 1,
            sort_by: "priority",
            order_by: "ASC",
            keyword: keyword,
        };
        var queryParam = new URLSearchParams(body);
        return WebService.getAPI({
            action: `kyc-question?` + queryParam,
            body: null,
            isShowError: true,
        })
            .then((res: any) => {
                setList(res.list)
                setTotalCount(res.count)
                setLoader(false)
                WebService.removeLoader('savequestion')
            })
            .catch((error) => {
                setLoader(false)
                WebService.removeLoader('savequestion')
                return error;
            });
    };

    const showEdit = (id: any) => {
        setValue("getCall", id)
        setSetupShowKYC(true);
        setLoader(true);
        WebService.removeLoader('savequestion')
        return WebService.getAPI({
            action: `kyc-question/getById?id=${id}`,
            body: null,
            isShowError: true,
        })
            .then((res: any) => {
                setId(res.result.id)
                setValue("question", res.result.question)
                setValue("type", res.result.dataType)
                setValue("priority", res.result.priority)
                var arr: any[] = [];
                {
                    res.result.dataType == "DROPDOWN" && res.result.kycQuestionOptionResponseList.length > 0 ?
                        (
                            res.result.kycQuestionOptionResponseList.map((res: any, index: any) => {
                                var obj = {
                                    label: res.label,
                                    index: res.id,
                                }
                                setValue(`dropdown_value_${res.id}`, res.label)
                                arr.push(obj)
                                setKeywordArr(arr)
                            })
                        )
                        :
                        (
                            res.result.kycQuestionOptionResponseList.map((res: any, index: any) => {
                                var obj = {
                                    value: res.id,
                                    label: res.label
                                }
                                arr.push(obj)
                                setSelectedParameter(arr)
                            })
                        )
                }
                setLoader(false);
                WebService.removeLoader('savequestion')
            })
            .catch((error) => {
                setLoader(false)
                WebService.removeLoader('savequestion')
                return error;
            })

    }


    const handleAddKeyword = () => {
        var arr = [...keywordArr];
        var obj = {
            label: "",
            index: ++keywordIndex
        }
        arr.push(obj);
        setKeywordArr(arr);
    };

    const handleDeleteKeyword = (index: any) => {
        if (id == "" || id == null || id == undefined) {
            var arr = [...keywordArr];
            arr.splice(index, 1)
            setKeywordArr(arr);
        }
        else {
            var arr = [...keywordArr];
            arr.splice(index, 1)
            setKeywordArr(arr);
        }
        // else {
        //     return WebService.deleteAPI({
        //         action: `kyc-question/option?id=${id}`
        //     }).then((res: any) => {
        //         toast.success(res.message)
        //         showEdit(watchVariable.getCall)
        //     }).catch((error) => {
        //         return error
        //     })
        // }
    };




    const parameterPromiseOptions = () =>
        new Promise<any[]>((resolve) => {
            return WebService.getAPI({
                action: `parameters?page=1&sort_by=parameter&order_by=ASC&keyword`,
            })
                .then((res: any) => {
                    if (res.list.length > 0) {
                        var updatedOptions = res.list.map((parameter: any) => ({
                            label: parameter.parameter,
                            value: parameter.id,
                        }));
                        resolve(updatedOptions);
                    }
                    else {
                        resolve([]);
                    }
                })
                .catch((error) => {
                    resolve([]);
                    return error;
                });
        });

    const onParameterSelection = (selectedOptions: any) => {
        var parameter: any = []
        setParameterFlag(false)
        // selectedOptions.map((arr: any) => {
        //     var obj = {
        //         label : arr.label,
        //         id : arr.value
        //     }
        //     parameter.push(obj)
        // })
        // setSelectedParameter(parameter);
        setSelectedParameter(selectedOptions)
    };

    const showKyc = () => {
        setSetupShowKYC(true)
        setId("")
        setSelectedParameter([])
    }


    return (
        <>
            <Row>
                <Col>
                    <div className="justify-content-between align items-center mb-3">
                        <h5 className="mt-2"><b>Manage KYC</b></h5>
                    </div>
                </Col>
                <Col md={5} className="d-flex justify-content-end gap-3 mb-3">
                    <InputGroup className="table-search-box border">
                        <Form.Control
                            placeholder="Search for anything"
                            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                setKeyword(e.currentTarget.value);
                                if (e.key === "Enter") {
                                    getKYC(page, e.currentTarget.value)
                                }
                            }}
                        />
                        <InputGroup.Text id="basic-addon1">
                            <IoSearchOutline className="icon" onClick={() => { getKYC(page, keyword) }} />
                        </InputGroup.Text>
                    </InputGroup>
                    <Button className="btn-brand-1 text-nowrap" id="add" onClick={showKyc}>
                        <FiPlus size={18} className="icon" />{t('add')}
                    </Button>
                </Col>
            </Row >
            {
                !loader ?
                    <div className="table-card table-responsive">
                        <Card className="table-container">
                            <Table className="table-style">
                                <thead>
                                    <tr>
                                        <th style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setSortBy("question");
                                                setClickable("question");
                                                { orderBy === "ASC" ? setOrderBy("DESC") : setOrderBy("ASC") }
                                            }}
                                        >
                                            Question
                                            {sortBy === "question" && orderBy === "DESC" ? (
                                                <MdOutlineKeyboardArrowDown
                                                    className="sort-icon cursor-pointer"
                                                    size={14}
                                                    onClick={() => {
                                                        setOrderBy("ASC");
                                                        setSortBy("question");
                                                    }}
                                                />
                                            ) : clickable === "question" ? (
                                                <MdOutlineKeyboardArrowUp
                                                    className="sort-icon cursor-pointer"
                                                    size={14}
                                                    onClick={() => {
                                                        setOrderBy("DESC");
                                                        setSortBy("question");
                                                    }}
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </th>
                                        <th style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setSortBy("createdDate");
                                                setClickable("createdDate");
                                                { orderBy === "ASC" ? setOrderBy("DESC") : setOrderBy("ASC") }
                                            }}
                                        >
                                            Modified Date
                                            {sortBy === "createdDate" && orderBy === "DESC" ? (
                                                <MdOutlineKeyboardArrowDown
                                                    className="sort-icon cursor-pointer"
                                                    size={14}
                                                    onClick={() => {
                                                        setOrderBy("ASC");
                                                        setSortBy("createdDate");
                                                    }}
                                                />
                                            ) : clickable === "createdDate" ? (
                                                <MdOutlineKeyboardArrowUp
                                                    className="sort-icon cursor-pointer"
                                                    size={14}
                                                    onClick={() => {
                                                        setOrderBy("DESC");
                                                        setSortBy("createdDate");
                                                    }}
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </th>
                                        {/* <th style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setSortBy("createdDate");
                                                setClickable("createdDate");
                                                { orderBy === "ASC" ? setOrderBy("DESC") : setOrderBy("ASC") }
                                            }}
                                        >
                                            Modified By
                                            {sortBy === "createdDate" && orderBy === "DESC" ? (
                                                <MdOutlineKeyboardArrowDown
                                                    className="sort-icon cursor-pointer"
                                                    size={14}
                                                    onClick={() => {
                                                        setOrderBy("ASC");
                                                        setSortBy("createdDate");
                                                    }}
                                                />
                                            ) : clickable === "createdDate" ? (
                                                <MdOutlineKeyboardArrowUp
                                                    className="sort-icon cursor-pointer"
                                                    size={14}
                                                    onClick={() => {
                                                        setOrderBy("DESC");
                                                        setSortBy("createdDate");
                                                    }}
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </th> */}
                                        <th style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setSortBy("data_type");
                                                setClickable("data_type");
                                                { orderBy == "ASC" ? setOrderBy("DESC") : setOrderBy("ASC") }
                                            }}
                                        >
                                            Type
                                            {sortBy === "data_type" && orderBy === "DESC" ? (
                                                <MdOutlineKeyboardArrowDown
                                                    className="sort-icon cursor-pointer"
                                                    size={14}
                                                    onClick={() => {
                                                        setOrderBy("ASC");
                                                        setSortBy("data_type");
                                                    }}
                                                />
                                            ) : clickable === "data_type" ? (
                                                <MdOutlineKeyboardArrowUp
                                                    className="sort-icon cursor-pointer"
                                                    size={14}
                                                    onClick={() => {
                                                        setOrderBy("DESC");
                                                        setSortBy("data_type");
                                                    }}
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </th>
                                        <th style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setSortBy("priority");
                                                setClickable("priority");
                                                { orderBy == "ASC" ? setOrderBy("DESC") : setOrderBy("ASC") }
                                            }}
                                        >
                                            Priority
                                            {sortBy === "priority" && orderBy === "DESC" ? (
                                                <MdOutlineKeyboardArrowDown
                                                    className="sort-icon cursor-pointer"
                                                    size={14}
                                                    onClick={() => {
                                                        setOrderBy("ASC");
                                                        setSortBy("priority");
                                                    }}
                                                />
                                            ) : clickable === "priority" ? (
                                                <MdOutlineKeyboardArrowUp
                                                    className="sort-icon cursor-pointer"
                                                    size={14}
                                                    onClick={() => {
                                                        setOrderBy("DESC");
                                                        setSortBy("priority");
                                                    }}
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </th>
                                        <th className="action-col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {list.length > 0 ?
                                        list.map((kycObj: any) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <TooltipCustom
                                                            st={
                                                                <span>
                                                                    {HelperService.truncate(
                                                                        HelperService.removeUnderScore(
                                                                            kycObj.question
                                                                        ),
                                                                        70
                                                                    )}
                                                                </span>
                                                            }
                                                            message={kycObj.question}
                                                            position="top"
                                                            id="c_btn"
                                                        ></TooltipCustom>
                                                    </td>
                                                    <td>{moment(kycObj.createdDate).format('DD-MM-YYYY, h:mm a')}</td>
                                                    {/* <td>{kycObj.createdBy}</td> */}
                                                    <td>{kycObj.dataType == "BOOLEANDOC" ? "BOOLEAN DOC" : kycObj.dataType}</td>
                                                    <td>{kycObj.priority}</td>
                                                    <td>
                                                        <div className="action-btns text-center">
                                                            <Button
                                                                className="action-btn btn-view"
                                                                title="Edit"
                                                                onClick={() => showEdit(kycObj.id)}>
                                                                <MdOutlineModeEdit className="icon" />{" "}
                                                                {t('Edit')}
                                                            </Button>
                                                            <Button
                                                                className="action-btn btn-delete"
                                                                title="Delete"
                                                                onClick={() => {
                                                                    setShowDelete(true)
                                                                    setValue("deleteId", kycObj.id)
                                                                }}
                                                            >
                                                                <PiTrash className="icon" />
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>)
                                        })
                                        :
                                        <>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <div className="table-wrap position-relative text-centre">
                                                        <div className="no-data-flound">
                                                            <div className="" >
                                                                <img src={NoDataFound} width={110} className="mb-3 text-centre" style={{ justifyContent: "center" }} />
                                                                <h3 className="text-secondary font-12">
                                                                    No Record Found
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                </tbody>
                            </Table>
                            <DeleteModal
                                action={`kyc-question?id=${watchVariable.deleteId}`}
                                show={showDelete}
                                onDelete={(deleted: boolean) => deleteList(deleted)}
                                deleteMsg={"Are you sure you want to delete?"}
                                btn={"YES/NO"}
                                deleteMethod={"DELETE"}
                            />
                        </Card>
                        {
                            !loader ?
                                <AIPagination
                                    pagination={10}
                                    totalItems={totalCount}
                                    changePage={(page: number) => {
                                        setPage(page);
                                    }}
                                    changeCurrentPage={page}
                                /> : <TableLoader />
                        }
                    </div>

                    : <TableLoader />
            }

            {
                showKYC ? (
                    <Offcanvas
                        show={showKYC}
                        placement="end"
                        className="righSide-modal size-md"
                        onHide={handleEditClose} >
                        <Form className="wmx-form-style" name="filterss" id="filterss" onSubmit={handleSubmit(saveQuestion)}>
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>{id == "" ? t('KYC_Question') : t('Edit_KYC_Question')}</Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <div className="mb-3">
                                    <FloatingLabel
                                        controlId="question"
                                        label={t("Question")}>
                                        <Form.Control
                                            as="textarea"
                                            className="h-auto"
                                            rows={4}
                                            maxLength={255}
                                            {...register("question", { required: true })}
                                            placeholder={t('Question')}
                                            autoComplete="off"
                                            onChange={(event) => {
                                                setValue("question", event.target.value)
                                                clearErrors('question')
                                            }}
                                            style={{ minHeight: "100px" }}
                                        />
                                    </FloatingLabel>
                                    {
                                        errors.question && (
                                            <Label
                                                title={t("Enter_Question")}
                                                modeError={true}
                                            />
                                        )
                                    }
                                    <div className=" d-flex justify-content-end">
                                        <span className="font-12">{watchVariable.question ? watchVariable.question.length : 0}/255</span>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className='form-group'>
                                        <select className='form-select' {...register("type", { required: true })}
                                            onChange={(event) => {
                                                setValue("type", event.target.value)
                                                clearErrors('type')
                                            }}>
                                            <option value={""} selected disabled>Select Type</option>
                                            <option value={"NUMBER"}>Number</option>
                                            <option value={"TEXT"}>Text</option>
                                            <option value={"DATE"}>Date</option>
                                            <option value={"BOOLEAN"}>Boolean</option>
                                            <option value={"DROPDOWN"}>Dropdown</option>
                                            <option value={"CHECKLIST"}>Checklist</option>
                                            <option value={"DOCUMENT"}>Document</option>
                                            <option value={"BOOLEANDOC"}>Boolean Document</option>
                                        </select>
                                    </div>
                                    {
                                        errors.type && (
                                            <Label
                                                title={t("Select_Type")}
                                                modeError={true}
                                            />
                                        )
                                    }
                                </div>
                                <div className="mb-3">
                                    <div className='form-group'>
                                        <FloatingLabel
                                            controlId="priority"
                                            label={t('Select_prio')}
                                        >
                                            <Form.Control
                                                type="number"
                                                {...register('priority', { required: true })}
                                                placeholder={t('Select_prio')}
                                                onChange={(event: any) => {
                                                    setValue("priority", event.target.value)
                                                    clearErrors('priority')
                                                }}
                                            />
                                        </FloatingLabel>
                                    </div>
                                    {
                                        errors.priority && (
                                            <Label
                                                title={t("Priority")}
                                                modeError={true}
                                            />
                                        )
                                    }
                                </div>
                                {
                                    watchVariable.type == "DROPDOWN" ?
                                        <Row>
                                            <Col>
                                                <div className="mb-3">
                                                    {
                                                        keywordArr.map((keyword: any, index: any) => {
                                                            return (
                                                                <div>
                                                                    <div className="d-flex gap-1 mb-2">
                                                                        <div className="w-100">
                                                                            <FloatingLabel
                                                                                controlId="dropdown"
                                                                                label={t('value')}
                                                                            >
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    {...register('dropdown_value_' + keyword.index, { required: true })}
                                                                                    placeholder={t('value')}
                                                                                    onChange={(event: any) => {
                                                                                        setValue(`dropdown_value_${keyword.index}`, event.target.value)
                                                                                        keyword.value = event.target.value;
                                                                                        keyword.label = event.target.value
                                                                                        clearErrors("dropdown_value_")
                                                                                    }}
                                                                                    defaultValue={keyword.value}
                                                                                />
                                                                            </FloatingLabel>
                                                                            {
                                                                                errors.dropdown_value_ && (
                                                                                    <Label
                                                                                        title={t("Enter_Value")}
                                                                                        modeError={true}
                                                                                    />
                                                                                )
                                                                            }
                                                                        </div>
                                                                        <div className="action-btns">
                                                                            {
                                                                                index !== 0 ? (
                                                                                    <Button
                                                                                        className="text-danger bg-danger-subtle border-0 px-4 "
                                                                                        title="Delete"
                                                                                        onClick={() =>
                                                                                            handleDeleteKeyword(index)
                                                                                        }
                                                                                    >
                                                                                        <PiTrash className="icon" />
                                                                                    </Button>
                                                                                ) : (
                                                                                    <Button
                                                                                        className="btn-brand text-nowrap"
                                                                                        onClick={() => {
                                                                                            handleAddKeyword();
                                                                                        }}
                                                                                    >
                                                                                        +{" "}{t('add')}
                                                                                    </Button>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        watchVariable["dropdown_value_" + keyword.index] == "" ?
                                                                            <Label
                                                                                title={t("Answer_Validation")}
                                                                                modeError={true}
                                                                            />
                                                                            :
                                                                            ""
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                        :
                                        ""
                                }
                                {
                                    watchVariable.type == "CHECKLIST" ?
                                        <>
                                            <div className="form-group">
                                                <AsyncSelect
                                                    // {...register('parameterId', { required: true })}
                                                    id="select_parameter"
                                                    isMulti
                                                    value={selectedParameter}
                                                    cacheOptions
                                                    defaultOptions
                                                    onChange={onParameterSelection}
                                                    loadOptions={parameterPromiseOptions}
                                                    placeholder={t('Select_Parameter')}

                                                />
                                            </div>
                                            {
                                                // (watchVariable.parameterId === undefined ||
                                                //     watchVariable.parameterId === "") &&
                                                parameterFlag ? (
                                                    <Label title={t("Please_Select_Parameter")} modeError={true} />
                                                ) : (
                                                    ""
                                                )}
                                        </>
                                        :
                                        ""
                                }
                                <div className="d-flex align-items-center gap-1">
                                    <input
                                        className="form-check-input mt-0 cursor-pointer"
                                        type="checkbox"
                                        id="flexSwitchCheckDefault"
                                        checked={isChecked}
                                        onChange={(e) => {
                                            setIsChecked(e.target.checked);
                                        }}
                                    />&nbsp;&nbsp;
                                    <span> IsMandatory</span>
                                </div>

                            </Offcanvas.Body>
                            <div className="offcanvas-footer">
                                <Button variant="secondary" onClick={handleEditClose}>
                                    <MdClose size={18} className="icon" />&nbsp; {t('cancel')}
                                </Button>
                                <Button variant="primary" type="submit" id="savequestion">
                                    <BsSave size={16} className="me-1" />&nbsp; {t('save')}
                                </Button>
                            </div>
                        </Form>
                    </Offcanvas >
                )
                    : ""
            }
        </>
    )
}

export default AddKYC