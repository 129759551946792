import moment from "moment";
const weatherIconURL = "https://openweathermap.org/img/wn/";
const HelperService = {
  getFormatedDateForDetail(dt: any, format?: any) {
    let stillUtc = moment.utc(dt).toDate();
    if (dt) {
      if (format) {
        return moment(stillUtc).local().format(format);
      } else {
        return moment(stillUtc)
          .local()
          .format(
            localStorage.getItem("date_format")?localStorage.getItem("date_format") + " hh:mm A" :
              "DD-MM-YYYY  hh:mm A"
          );  
          
      }
    }
  },
  getIconForWeather(icon: string) {
    if (icon !== "") return `${weatherIconURL}${icon}.png`;
  },
  removeUnderScore(str: string) {
    str = this.titleCase(str);
    if (str && str.includes("_")) {
      let str1 = str.replace("_", " ");
      return str1;
    } else {
      if (str == "Sms") return str.toUpperCase();
      else return str;
    }
  },

  titleCase(str: any) {
    if (str) {
      let str1 = str?.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      return str1;
    }
  },
  truncate(str: any, limit: number) {
    if (str) {
      return str.length > limit ? str.substring(0, limit) + "..." : str;
    }
  },
  getFormatedNumber(dt: Number, prefix: any, suffix: any) {
    if (dt) {
      if (prefix) {
        return (prefix ? prefix : "") + "" + dt;
      } else {
        return dt + "" + (suffix ? suffix : "");
      }
    } else {
      if (prefix) {
        return (prefix ? prefix : "") + "" + 0;
      } else {
        return 0 + "" + (suffix ? suffix : "");
      }
    }
  },
  abbrNum(number: any, decPlaces: any) {
    if (!number) {
      return number;
    }
  
    decPlaces = Math.pow(10, decPlaces);
    let abbrev = ["k", "m", "b", "t"];
    for (let i = abbrev.length - 1; i >= 0; i--) {
      let size = Math.pow(10, (i + 1) * 3);
      if (size <= number) {
        number = Math.round((number * decPlaces) / size) / decPlaces;
        number += abbrev[i];
        break;
      } else {
        number = Math.round(number);
      }
    }
    return number;
  },
  getFormatedStatusText(dt: string) {
    if (dt != "") {
      if(dt.includes("_")){
      dt = dt.replaceAll("_", " ");}
      dt = dt[0].toUpperCase() + dt.slice(1).toLowerCase();
      return dt;
    } else {
      return "";
    }
  },
};



export default HelperService;
