import React, { useEffect, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { IoSearchOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NoDataFound from "../../assets/images/empty_item.svg";
import FridgeGrren from "../../assets/images/fridge-green.svg";
import RedFridge from "../../assets/images/fridge-red.svg";
import WebService from "../../Services/WebService";
import TableLoader from "../Common/TableLoader/TableLoader";
import AsyncSelect from "react-select/async";
import Caution from "../../assets/images/caution.svg";
import { MdOutlinePushPin } from "react-icons/md";
import { MdPushPin } from "react-icons/md";
import { toast } from "react-toastify";
interface Station {
  stationName: string;
  id: string;
  comapanyId: string;
  online: boolean;
  pin: boolean;
}
const CommandCentre = () => {
  const { t } = useTranslation();
  const [keyword, setKeyword] = useState("");
  const [loader, setLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [stationList, setStationList] = useState<Station[]>([]);
  const [index, setIndex] = useState(0);
  const [sortBy, setSortBy] = useState("stationName");
  const [orderBy, setOrderBy] = useState("ASC");
  const [selectedClient, setSelectedClient] = useState({
    value: "",
    label: "Select Client Name",
  });
  let navigate = useNavigate();

  useEffect(() => {
    let role = localStorage.getItem("role");
    if (role === "MANAGER" || role === "CLIENT") {
        navigate("/access-denied")
    }
    
    if (index === 0) {
      getCommandCentre(page, selectedClient?.value,"pin");
    }
    const intervalId = setInterval(() => {
      getCommandCentre(page, "","pin");
    }, 30000);

    return () => clearInterval(intervalId);

  }, [page, sortBy, orderBy, keyword]);

  const getCommandCentre = (page: any, company_id: any, sortByPin?: any) => {
    if (index === 0) {
      setLoader(true);
    }

    const body = {
      page: page,
      order_by: sortByPin ? "DESC" : orderBy,
      sort_by: sortByPin ? sortByPin : sortBy,
      keyword: keyword,
      company_id: company_id,
    };
    var queryParam = new URLSearchParams(body);
    return WebService.getAPI({
      action: `command-centre/stations?` + queryParam,
      body: null,
      isShowError: true,
    })
      .then((res: any) => {
        if (index === 0) {
          setLoader(false);
          setIndex(1);
        }

        setStationList(res.list);
        setTotalCount(res.count);
      })
      .catch((error) => {
        setLoader(false);
        return error;
      });
  };
  const handlePinStation = (station_id: any, pin_status: boolean) => {
    const body = {
      stationId: station_id,
      pin: pin_status,
    };

    return WebService.putAPI({
      action: `station/pin`,
      body: body,
      isShowError: true,
    })
      .then((res: any) => {
        toast.success(res?.message);
        getCommandCentre(page, selectedClient?.value, "pin");
      })
      .catch((error) => {
        setLoader(false);
        return error;
      });
  };

  const clientPromiseOptions = (inputValue: any) =>
    new Promise<any[]>((resolve) => {
      const body = {
        page: page.toString(),
        order_by: orderBy,
        sot_by: sortBy,
        keyword: keyword,
      };
      var queryParam = new URLSearchParams(body);
      return WebService.getAPI({
        action: `companies?` + queryParam,
        // action: `companies?=${inputValue}`,
        body: null,
        isShowError: true,
      })
        .then((res: any) => {
          if (res.list.length > 0) {
            var updatedOptions = res.list.map((companyName: any) => ({
              value: companyName.id,
              label: companyName.name,
            }));
            updatedOptions.unshift({
              value: "",
              label: "All",
            })
            resolve(updatedOptions);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          resolve([]);
          return error;
        });
    });

  const onClientSelection = (selectedOption: any) => {
    console.log(selectedOption);
    setSelectedClient(selectedOption);
    getCommandCentre(page, selectedOption?.value,"pin");
  };
  return (
    <>
      <Row>
        <Col>
          <div className="justify-content-between align items-center mb-3">
            <h5 className="mt-2 mr-3">
              <b>Manage Command Centre</b>
            </h5>
          </div>
        </Col>
        <Col>
          <div className="form-group">
            <AsyncSelect
              id="select_client"
              value={selectedClient}
              cacheOptions
              defaultOptions
              onChange={onClientSelection}
              loadOptions={clientPromiseOptions}
              placeholder={"Select Client"}
            />
          </div>
        </Col>
        <Col md={5} className="d-flex justify-content-end gap-3 mb-3">
          <InputGroup className="table-search-box border">
            <Form.Control
              placeholder={t("Search_for_anything")}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter") {
                  setKeyword(e.currentTarget.value);
                }
              }}
            />
            <InputGroup.Text id="basic-addon1">
              <IoSearchOutline className="icon" />
            </InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>

      <div className="table-card card-boxes">
        <Row>
          {!loader ? (
            stationList.length > 0 ? (
              stationList.map((station: Station, index: any) => (
                <Col lg={3} sm={3} key={index} className="position-relative">
                  {station?.pin === false ? (
                    <div className="position-abs">
                      <MdOutlinePushPin
                        size={20}
                        onClick={() => {
                          // setIndex(0)
                          handlePinStation(station?.id, true);
                        }}
                      />
                    </div>
                  ) : (
                    <div className="position-abs">
                      <MdPushPin
                        size={20}
                        onClick={() => {
                          // setIndex(0)
                          handlePinStation(station?.id, false);
                        }}
                      /></div>
                  )}

                  <div>
                    <Link
                      to="javascript:void(0)"
                      onClick={() => {
                        navigate(
                          `/station-view/${station?.comapanyId}/${station?.id}`
                        );
                      }}
                    >
                      <div
                        className={
                          station?.online
                            ? "green-box d-flex justify-content-between align-items-center mb-3"
                            : "red-box d-flex justify-content-between align-items-center mb-3"
                        }
                        id="green-box "
                      >
                        <div className="col me-1">
                          <img
                            src={station?.online ? FridgeGrren : RedFridge}
                            alt=""
                            className="margin-mins"
                          />
                          <span className="main-title">
                            {station?.stationName}
                          </span>
                          <br />
                          <span className="sub-title">
                            {station?.online ? "Working" : "Not Working"}
                          </span>
                        </div>
                        <div className="d-flex flex-column">
                          {!station?.online && (
                            <div>
                              <img src={Caution} alt="" />
                            </div>
                          )}
                        </div>
                      </div>
                    </Link>
                  </div>
                </Col>
              ))
            ) : (
              <div className="table-wrap position-relative text-center ">
                <div className="no-data-flound">
                  <div className="text-center">
                    <img
                      src={NoDataFound}
                      width={110}
                      className="mb-3 text-centre"
                      style={{ justifyContent: "center" }}
                    />
                    <h3 className="text-secondary font-12">No Record Found</h3>
                  </div>
                </div>
              </div>
            )
          ) : (
            <TableLoader />
          )}
        </Row>
      </div>
    </>
  );
};
export default CommandCentre;
